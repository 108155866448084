import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from 'react-router-dom'
import axios from 'axios'
import backgroundImage from "./images/wc logo2.webp";
import Header from './Header';
import Footer from './Footer';
const Books = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  /*********The Counter To Refresh The Data Info Views Starts Here*****************/
  let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 360000
  setInterval(() => {
    setCount(count + 1)
  }, delay)
  /*********The Counter To Refresh The Data Info Views Ends Here**********************/

  let [fileDoc, setFileDoc] = useState('')
  let [givenFile, setGivenFile ]= useState('')

  const downloadAdventurers = file => {
    setFileDoc(file)
    setGivenFile(`materials/adventurers/books/${file}`)

    //alert(givenFile)
}


const downloadPathfinders = file => {
  setFileDoc(file)
  setGivenFile(`materials/pathfinders/books/${file}`)
}


const downloadAmbassadors = file => {
  setFileDoc(file)
  setGivenFile(`materials/ambassadors/books/${file}`)
}


const downloadPcm = file => {
  setFileDoc(file)
  setGivenFile(`materials/pcm/books/${file}`)
}


const downloadYoungAdults = file => {
  setFileDoc(file)
  setGivenFile(`materials/young adults/books/${file}`)
}


const downloadMg = file => {
  setFileDoc(file)
  setGivenFile(`materials/mg/books/${file}`)
}


const downloadSyl = file => {
  setFileDoc(file)
  setGivenFile(`materials/syl/books/${file}`)
}


const downloadCmt = file => {
  setFileDoc(file)
  setGivenFile(`materials/cmt/books/${file}`)
}

useEffect(() => {
  if(fileDoc != '')
  downloadDoc();
}, [fileDoc]);

const downloadDoc = async () => {
 //alert("Your Download Will Start Soon")
  try {
          const response = await axios.get(server_app_api_php+'/resources/downloadAYMaterials.php?file='+givenFile, {
              responseType: 'blob'
          });
          if (response.status === 200) {
            //alert(response.data);
              const blob = new Blob([response.data], { type: 'application/octet-stream' });
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(blob);
              downloadLink.download = fileDoc;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
          }
      } catch (error) {
          alert('Error downloading file:', error);
      }
      setFileDoc('')
      setGivenFile('')
}

  return (
    <>

<div>
<Header />
  <main>

  <div class="container">
<hr class='mt-5' />
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed scrolling-navbar mt-5">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav">
           
          <li className="nav-item">
              <a className="nav-link" href="#Adventurers" title='Adventurers Ministries'>Adventurers</a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#Pathfinders" title='Pathfinders Ministries'>Pathfinders</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Ambassadors" title='Ambassadors Ministries'>Ambassadors</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Young-Adults" title='Young Adults Ministries'>Young Adults</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#PCM" title='Public Campus Ministries'>PCM</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Master-Guides" >Master Guides</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#SYL" title='Senior Youth Leadership'>SYL</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#CMT" title='Club Ministry Training'>CMT</a>
            </li>

          </ul>
        </div>
      </div>
    </nav>


  <section id="news" class="mt-2 mb-4">
  <div id='Adventurers' class="row mt-5 mb-5 pt-3 wow fadeIn" data-wow-delay=".4s">
<div className='col-md-12 text-center'>
<h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 py-5 wow fadeIn" data-wow-delay=".2s">ADVENTURERS MINISTRY</h2>
</div>
<hr class=" mb-4" />


<div class="col-lg-2 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/adventurers/images/Adventurer Directors Manual image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAdventurers("Adventurer Directors Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Adventurer Director's Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAdventurers("Adventurer Directors Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            <div class="col-lg-2 mb-md-0 mb-4">
              <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/adventurers/images/Award Book 2020 image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
              
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAdventurers("Award Book 2020.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Adventurer Award Book</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAdventurers("Award Book 2020.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              

            <div class="col-lg-2 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/adventurers/images/little lamb image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAdventurers("Little Lamb.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Little Lamb</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAdventurers("Little Lamb.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/adventurers/images/early bird image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAdventurers("Early Bird.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Early Bird</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAdventurers("Early Bird.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/adventurers/images/busy bee image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAdventurers("Busy Bee.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Busy Bee</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAdventurers("Busy Bee.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-2 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/adventurers/images/sun beam image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAdventurers("Sun Beam.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Sun Beam</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAdventurers("Sun Beam.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
</div>
            

            
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/adventurers/images/builder image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAdventurers("Builder.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Builder</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAdventurers("Builder.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/adventurers/images/helping hands image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAdventurers("Helping Hands.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Helping Hands</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAdventurers("Helping Hands.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
           
            
            </div>
          <hr class="mb-5" />

 <div id='Pathfinders' class="row mt-5 mb-5 pt-3 wow fadeIn" data-wow-delay=".4s">
 <div className='col-md-12 text-center'>
          <h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 py-5 wow fadeIn" data-wow-delay=".2s">PATHFINDERS MINISTRY</h2>
</div>
<hr class=" mb-4" />

<div class="col-lg-2 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/friend manual image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Friend Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Friend Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Friend Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/companion manual image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Companion Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Companion Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Companion Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/explorer manual image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Explorer Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Explorer Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Explorer Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-2 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/ranger manual image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5" onClick={() => { downloadPathfinders("Ranger Manual.pdf")  }}>
              <h4 class="text-uppercase mb-4"><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ranger Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Ranger Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
</div>
            

            
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/voyager manual image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Voyager Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Voyager Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Voyager Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/guide manual image.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Guide Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Guide Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Guide Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
          

              <div class="col-lg-2 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/Pathfinder Administrative Manual_5 2004.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Pathfinder Administrative Manual_5 2004.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Pathfinder Administrative Manual_5 2004</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Pathfinder Administrative Manual_5 2004.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/Pathfinder Basic Staff Training Course.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Pathfinder Basic Staff Training Course.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Pathfinder Basic Staff Training Course</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Pathfinder Basic Staff Training Course.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/Pathfinder Story.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Pathfinder Story.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Pathfinder Story</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Pathfinder Story.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-2 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/The Happy Path Small.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("The Happy Path Small.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| The Happy Path</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("The Happy Path Small.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
</div>
            

            
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/Church Heritage Manual.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Church Heritage.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Church Heritage Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Church Heritage.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pathfinders/images/Bible Truth Manual.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPathfinders("Bible Truths Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Bible Truths Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPathfinders("Bible Truths Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            
            </div>
          <hr class="mb-5" />


<div id='Ambassadors' class="row mt-5 mb-2 pt-3 wow fadeIn" data-wow-delay=".4s">
<div className='col-md-12 text-center'>
          <h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 py-5 wow fadeIn" data-wow-delay=".2s">AMBASSADORS MINISTRY</h2>
</div>
<hr class=" mb-4" />

  <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Ambassadors Administrative Manual.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Ambassadors Administrative Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Administrative Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"><span onClick={() => { downloadAmbassadors("Ambassadors Administrative Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div> 
              
              <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 1 Leader.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 1 Leader.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 1 (Leader)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 1 Leader.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 1 Participant.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 1 Participant.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 1 (Participant)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 1 Participant.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 2 Leader.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 2 Leader.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 2 (Leader)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 2 Leader.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
           
           
            <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 2 Participant.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 2 Participant.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 2 (Participant)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 2 Participant.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 3 Leader.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 3 Leader.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 3 (Leader)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 3 Leader.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 3 Participant.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 3 Participant.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 3 (Participant)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 3 Participant.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 4 Leaders.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 4 Leaders.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 4 (Leader)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 4 Leaders.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
</div>
            

            
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 4 Participant.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 4 Participant.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 4 (Participant)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 4 Participant.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 5 Leader.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 5 Leader.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 5 (Leader)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 5 Leader.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
           
 
            <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 5 Participant.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 5 Participant.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 5 (Participant)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 5 Participant.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 6 Leader.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 6 Leader.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 6 (Leader)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 6 Leader.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 6 Participant.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 6 Participant.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 6 (Participant)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 6 Participant.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 7 Leader.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 7 Leader.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 7 (Leader)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 7 Leader.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
</div>
            

            
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/ambassadors/images/Module 7 Participant.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadAmbassadors("Module 7 Participant.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Ambassador Module 7 (Participant)</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadAmbassadors("Module 7 Participant.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
                   
            
            </div>
          <hr class="mb-5" />

<div id='PCM' class="row mt-5 mb-2 pt-3 wow fadeIn" data-wow-delay=".4s">
<div className='col-md-12 text-center'>
          <h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 py-5 wow fadeIn" data-wow-delay=".2s">PUBLIC CAMPUS MINISTRY</h2>
</div>
<hr class=" mb-4" />

      
        <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pcm/images/PCM Manual.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4"  onClick={() => { downloadPcm("PCM Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| PCM Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadPcm("PCM Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/pcm/images/PCM Student Handbook.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadPcm("PCM Student Handbook.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| PCM Student Handbook</a></h4>
              <p class="grey-text-3 font-thin" align="justify"><span onClick={() => { downloadPcm("PCM Student Handbook.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
     
        </div>
<hr class="mb-5" />

<div id='Young-Adults' class="row mt-5 mb-2 pt-3 wow fadeIn" data-wow-delay=".4s">
<div className='col-md-12 text-center'>
     <h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 py-5 wow fadeIn" data-wow-delay=".2s">YOUNG ADULTS MINISTRY</h2>
</div>
<hr class=" mb-4" />

       
        <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Young Adults Manual.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Young Adults Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Senior Youth Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Young Adults Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              
            <div class="col-lg-2 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Pastors and Elders manual on youth ministry.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Pastors and Elders manual on youth ministry.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Pastors and Elders Manual on Youth Ministry</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Pastors and Elders manual on youth ministry.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
     
              <div class="col-lg-2 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/AY Story.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-2 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("AY Story.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| AY Story</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("AY Story.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

            <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Incorporating Mentoring in Your Ministry.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Incorporating Mentoring in Your Ministry.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Incorporating Mentoring in Your Ministry</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Incorporating Mentoring in Your Ministry.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
            
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/The Need for Being Connected.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("The Need for Being Connected.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| The Need for Being Connected</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("The Need for Being Connected.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

           <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Definitions and Types of Mentoring.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Definitions and Types of Mentoring.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Definitions and Types of Mentoring</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Definitions and Types of Mentoring.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
</div>
            

            
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Benefits of Mentoring.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Benefits of Mentoring.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Benefits of Mentoring</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Benefits of Mentoring.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Being a Mentor.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Being a Mentor.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Being a Mentor</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Being a Mentor.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Being a Mentor Partner.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Being a Mentor Partner.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Being a Mentor Partner</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Being a Mentor Partner.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
           
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Passing on Values.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Passing on Values.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Passing on Values</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Passing on Values.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Listening Skills.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Listening Skills.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Listening Skills</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Listening Skills.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/Steps to Discipleship.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("Steps to Discipleship.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Steps To Discipleship</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("Steps to Discipleship.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/DIA-Series-1.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("DIA-Series-1.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Disciples In Action - Series 1</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("DIA-Series-1.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
</div>
            

            
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/DIA-Series-2.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("DIA-Series-2.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Disciples In Action - Series 2</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("DIA-Series-2.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                 
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/DIA-Series-3.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("DIA-Series-3.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Disciples In Action - Series 3</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("DIA-Series-3.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

                      
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/DIA-Series-4.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("DIA-Series-4.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Disciples In Action - Series 4</a></h4>
              <p class="grey-text-3 font-thin" align="justify"><span onClick={() => { downloadYoungAdults("DIA-Series-4.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>              
 
            <div class="col-lg-3 mb-md-0 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/young adults/images/DIA-Series-5.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadYoungAdults("DIA-Series-5.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Disciples In Action - Series 5</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadYoungAdults("DIA-Series-5.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>          
           
            
            </div>
          <hr class="mb-5" />

    
    
<div id='Master-Guides' class="row mt-5 mb-2 pt-3 wow fadeIn" data-wow-delay=".4s">
<div className='col-md-12 text-center'>
<h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 py-5 wow fadeIn" data-wow-delay=".2s">MASTER GUIDE</h2>
</div>
<hr class=" mb-4" />

         
        <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/mg/images/MG-curriculum-manual.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" 
                  />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadMg("MG-curriculum-manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| MG Curriculum Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> 
              <span onClick={() => { downloadMg("MG-curriculum-manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span>
              </p>
              </div>
              
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/mg/images/MG 22 Appendix Workshop Resources.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" 
                  />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadMg("MG 22 Appendix Workshop Resources.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| MG Workshop Resources</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> 
              <span onClick={() => { downloadMg("MG 22 Appendix Workshop Resources.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span>
              </p>
              </div>
     
        </div>
<hr class="mb-5" />



<div id='SYL' class="row mt-5 mb-2 pt-3 wow fadeIn" data-wow-delay=".4s">
<div className='col-md-12 text-center'>
<h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 py-5 wow fadeIn" data-wow-delay=".2s">SENIOR YOUTH LEADER</h2>
</div>
<hr class=" mb-4" />

     
        <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 1 Introduction.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 1 Introduction.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 1 Introduction</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 1 Introduction.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 2 Youth Development.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 2 Youth Development.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 2 Youth Development</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 2 Youth Development.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 3 Need for specialized ministry.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 3 Need for specialized ministry.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 3 Need for specialized ministry</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 3 Need for specialized ministry.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 4 Leadership.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 4 Leadership.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 4 Leadership</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 4 Leadership.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 5 Mentoring.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 5 Mentoring.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 5 Mentoring</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 5 Mentoring.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 6 Church Planning.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 6 Church Planning.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 6 Church Planning</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 6 Church Planning.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 7 Creative Youth Ministry.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 7 Creative Youth Ministry.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 7 Creative Youth Ministry</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 7 Creative Youth Ministry.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 8 Serving.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 8 Serving.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 8 Serving</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 8 Serving.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 9 Outreach.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 9 Outreach.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 9 Outreach</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 9 Outreach.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/Seminar 10 Digital Ministry.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("Seminar 10 Digital Ministry.pptx")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| Seminar 10 Digital Ministry</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("Seminar 10 Digital Ministry.pptx")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>

              <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/syl/images/SYL Manual.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadSyl("SYL Manual.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| SYL Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify">  <span onClick={() => { downloadSyl("SYL Manual.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
     
        </div>
<hr class="mb-5" />

<div id='CMT' class="row mt-5 mb-2 pt-3 wow fadeIn" data-wow-delay=".4s">
<div className='col-md-12 text-center'>
<h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 py-5 wow fadeIn" data-wow-delay=".2s">CLUB MINISTRY TRAINING</h2>
</div>
<hr class=" mb-4" />

     
        <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/cmt/images/CMT 2022.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4"  onClick={() => { downloadCmt("CMT 2022.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| CMT Manual</a></h4>
              <p class="grey-text-3 font-thin" align="justify"> <span onClick={() => { downloadCmt("CMT 2022.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
              
            <div class="col-lg-3 mb-md-12 mb-4">
                <div class="view overlay z-depth-1">
                <img src={server_app_api_php+'/resources/materials/cmt/images/WC CMT REQUIREMENT CARD 2023.jpg'} class="img-fluid z-depth-1-half"
                  alt="Post Image" />
                <div class="mask rgba-white-slight"></div>
              </div>
              </div>
            <div class="col-lg-3 ml-lg-12 mb-5">
              <h4 class="text-uppercase mb-4" onClick={() => { downloadCmt("WC CMT REQUIREMENT CARD 2023.pdf")  }}><a href="#!" class="black-text-2 font-weight-bold pb-3">| CMT Requirements Card</a></h4>
              <p class="grey-text-3 font-thin" align="justify"><span onClick={() => { downloadCmt("WC CMT REQUIREMENT CARD 2023.pdf")  }}
               type='button' class='fa fa-download green-text' title='Download'></span></p>
              </div>
     
        </div>
<hr class="mb-5" />


</section>
</div>


 

  </main>
 
 
  <Footer />
        
      </div>
    </>
  )
}

export default Books
