import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBullseye, faFlag, faHandsHelping, faChurch } from '@fortawesome/free-solid-svg-icons';
import './YoungAdults.css';
import LeftSleeve from './images/Left-Sleeve-and-Insignia-Pl.jpg';
import RightSleeve from './images/Right-Sleeve-and-Insignia-P.webp';
import adventurersSong from './images/we_are_adventurers_music.jpg';
import planningCommitImage from './images/young adults.jpg';

const YoungAdults = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
           <div className="overlay">
                <Header />
                <hr className="mt-5 mb-5" />
                <main>
                    <div className="container mt-5">
                        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed scrolling-navbar">
                            <div className="container">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav"
                                    aria-controls="navbarNav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={toggleNavbar}
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Objectives">Philosophy & Objectives</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Mission-Aim-Motto-And-Pledge" title='Mission, Aim, Motto and Pledge'>Mission</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="Adventurers" title='Adventurers Ministries'>Adventurers</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="Pathfinders" title='Pathfinders Ministries'>Pathfinders</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="Ambassadors" title='Ambassadors Ministries'>Ambassadors</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="PCM" title='Public Campus Ministries'>PCM</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        <section className="container-fluid mt-5">
                            <article>
                                <div>
                                    <div>
                                        <div id='Objectives' className='row d-flex'>
                                            <div className='col-md-12 d-flex'>
                                            <div className="card flex-fill">
                                            <div className="card-header bg-transparent border-0 d-flex align-items-center">
                                                <h2 className="h4 mt-5 text-uppercase">Objectives</h2>
                                                </div>
                                                <div className="card-body">
                                                <p>“To save from sin and guide into service:” this true and only motive, so complete and impressive, was adopted during the 1926 General Conference session.&nbsp; To obtain the salvation of the youth it is necessary to give them every possible opportunity to participate in all Adventist youth activities.&nbsp; By keeping youth actively preparing for more efficient service, they are protected from evil.&nbsp; It is necessary to place them in some activity as soon as they are ready.&nbsp; “Seeking the good of others is the way in which true happiness can be found” (Counsels on Stewardship, p. 24).&nbsp; The enemy will not prevail against youth who are actively engaged in the things of God.</p>
                                                <p>That the youth may work for:</p>
                                                <ul>
                                                    <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> Other youth</li>
                                                    <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> Their church</li>
                                                    <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> Their fellow men</li>
                                                </ul>
                                                <p>This triple purpose that God has outlined for His youth is really the second part of the objective presented in a practical sense: “guide into service.”&nbsp; From the time youth missionary work first began, this triple purpose has been put into practice.&nbsp; The goal is to save each Adventist youth who faces the battle against sin, striving to rescue more and more souls for the kingdom of God.&nbsp; In 1947 the dynamic slogan “Share your Faith” brought new emphasis to soul-winning around the world.
                                                That the youth may work for other youth.&nbsp; Educate the youth to help the youth; and in seeking to do this work each will gain experience that will qualify him to become a consecrated worker in a larger sphere” (Messages to Young People, p. 208).
                                                “He [Satan] well knows that there is no other class that can do as much good as young men and young women who are consecrated to God.&nbsp; The youth, if right, could sway a mighty influence.&nbsp; Preachers or laymen advanced in years, cannot have one-half the influence upon the young that the youth, devoted to God, can have upon their associates” (Messages to Young People, p. 204).
                                                “Young men and women, God calls upon you to work, work for Him&#8230; You can do a work that those who minister in word and doctrine cannot do.&nbsp; You can reach a class whom the minister cannot affect” (Messages to Young People, p. 207).
                                                That the youth work for the church.&nbsp; The youth should work for the church, and for “those who profess to be Sabbath-keepers.”&nbsp; Faithfully attending the services of the church, the prayer meetings, and the missionary meetings; helping in the Sabbath School and in the AY Society, and participating in the missionary activities of the church, they encourage and strengthen the church. “Loyalty to Christ demands the faithful performance of church duties” (Education, p. 269).
                                                “The church is languishing for the help of young men who will bear a courageous testimony, who will with their ardent zeal stir up the sluggish energies of God’s people, and so increase the power of the church in the world” (Message to Young People, p. 25).
                                                The youth may work for youth not of the faith.&nbsp; “Time is short.&nbsp; Workers for Christ are needed everywhere.&nbsp; There should be one hundred earnest, faithful laborers in home and foreign mission fields where now there is one.&nbsp; The highways and the byways are yet un-worked” (Fundamentals of Christian Education, p. 488).
                                                There is need for those who would work from house to house.&nbsp; “The Lord calls upon our youth to labor as canvassers and evangelists, to do house-to-house work in places that have not yet heard the truth” (Messages to Young People, p. 220).
                                                “The Lord calls for decided efforts to be put forth in places where the people know nothing of Bible truth.&nbsp; Singing and prayer and Bible readings are needed in the homes of the people” (Counsels to Teachers, p. 540).
                                                “They [the youth] can form themselves into bands to do Christian help work&#8230; They will find many opportunities to use the talent that God has given them in carrying melody and sunshine into many lonely places darkened by sorrow and affliction, singing to those who seldom have church privileges” (Counsels to Teachers, p. 547).
                                                Companies should be organized and thoroughly educated to work as nurses, gospel visitors, and Bible readers, as canvassers, ministers, and medical missionary evangelists” (Counsels to Teachers, p. 546).
                                                The AY Society that keeps these three purposes in mind will become a dynamic and spiritual influence of the church, and this is what it should be.&nbsp; The success of every AY Society depends on the faithful fulfillment of these purposes and the way in which the members apply the spirit of the AY Pledge to their lives.
                                                “When the youth give their hearts to God, our responsibility for them does not cease. They must be interested in the Lord’s work, and led to see that He expects them to do something to advance His cause.&nbsp; It is not enough to show how much needs to be done, and to urge the youth to act a part.&nbsp; They must be taught how to labor for the Master.&nbsp; They must be trained, disciplined, drilled, in the best methods of winning souls to Christ.&nbsp; Teach them to try in a quiet, unpretending way to help their young companions. Let different branches of missionary effort be systematically laid out, in which they may take part, and let them be given instruction and help.&nbsp; Thus they will learn to work for God” (Gospel Workers, p. 210).
                                            </p>
                                            </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div id='Mission-Aim-Motto-And-Pledge' className='row mb-2 mt-5 d-flex'>
                                            <div className='col-12 text-center bg-dark mb-3'>
                                                <span className="h2 text-uppercase py-5 title-color">Mission, Aim, Motto and Pledge</span>
                                            </div>

                                            <div className='col-md-3 mb-3'>
                                                <div className="card text-white">
                                                    <div className="card-header text-center">
                                                        <FontAwesomeIcon icon={faBullseye} style={{ color: 'navy' }} />
                                                        <span className="mt-2 text-uppercase ml-2" style={{ color: 'skyblue' }}>Mission Statement</span>
                                                    </div>
                                                    <div className="card-body">
                                                        <p>To lead young people into a saving relationship with Jesus Christ and help them embrace His call to discipleship.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-3 mb-3'>
                                                <div className="card text-white">
                                                    <div className="card-header text-center">
                                                        <FontAwesomeIcon icon={faFlag} style={{ color: 'navy' }} />
                                                        <span className="mt-2 text-uppercase ml-2" style={{ color: 'skyblue' }}>Aim</span>
                                                    </div>
                                                    <div className="card-body">
                                                        <p>The Advent Message to All the World in My Generation</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-3 mb-3'>
                                                <div className="card text-white">
                                                    <div className="card-header text-center">
                                                        <FontAwesomeIcon icon={faHandsHelping} style={{ color: 'navy' }} />
                                                        <span className="mt-2 text-uppercase ml-2" style={{ color: 'skyblue' }}>Motto</span>
                                                    </div>
                                                    <div className="card-body">
                                                        <p>The Love of Christ Compels Me.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-3 mb-3'>
                                                <div className="card text-white">
                                                    <div className="card-header text-center">
                                                        <FontAwesomeIcon icon={faChurch} style={{ color: 'navy' }} />
                                                        <span className="mt-2 text-uppercase ml-2" style={{ color: 'skyblue' }}>Pledge</span>
                                                    </div>
                                                    <div className="card-body">
                                                        <p>Loving the Lord Jesus, I promise to take an active part in the youth ministry of the church, doing what I can to help others and to finish the work of the Gospel in all the world.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>
                </main>
                <Footer />
            </div>
            <div className="overlay-background" style={{ backgroundImage: `url(${planningCommitImage})` }}></div>
        </>
    );
};

export default YoungAdults;
