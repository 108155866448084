import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faListAlt, faTshirt, faMapMarkerAlt, faMusic, faCheck } from '@fortawesome/free-solid-svg-icons';
import LeftSleeve from './images/Left-Sleeve-and-Insignia-Pl.jpg';
import RightSleeve from './images/Right-Sleeve-and-Insignia-P.webp';
import adventurersSong from './images/we_are_adventurers_music.jpg';
import planningCommitImage from './images/planning_committee_in_adventurers_uniform.jpg'; // Importing the background image
import './Adventurers.css';

const Adventurers = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="overlay">
        <Header />
        <hr className='mt-5 mb-5' />
        <main>
          <div className="container mt-3">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
              <div className="container">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggleNavbar}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="#Philosophy-Objectives">Philosophy & Objectives</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Pledge-Law">Pledge & Law</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Uniform">Uniform</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Insignia-Placement">Insignia Placement</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Song">Song</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="Pathfinders">Pathfinders</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="Ambassadors">Ambassadors</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="YoungAdults">Young Adults</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="PCM">PCM</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <section className="container-fluid mt-3">
              <article className="c-article l-grid-item l-grid-item--l--4-col l-grid-item--xxl--3-col post-1839 page type-page status-publish format-standard hentry">
                <div className="c-article__body mt-3">
                  <div className="text u-spacing">

                    <div id='Philosophy-Objectives' className='row mt-3 py-3 d-flex'>
                      <div className='col-md-6 d-flex mb-3'>
                        <div className="card flex-fill">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center">
                            <FontAwesomeIcon icon={faCross} className="me-2 text-burgundy fa-lg" />
                            <h2 className="h4 text-uppercase text-navy">PHILOSOPHY</h2>
                          </div>
                          <div className="card-body">
                            <p>
                              The Adventurer Club is a Seventh-day Adventist church-sponsored ministry open to all children ages 4-9, in which the church, home, and school join together to help children grow joyfully in wisdom and stature and in favor with God and man.
                              The Adventurer Club is offered to assist parents in making the development of their child richer and more meaningful.
                              The Adventurer Club provides fun and creative ways for children.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6 d-flex mb-3'>
                        <div className="card flex-fill">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center">
                            <FontAwesomeIcon icon={faListAlt} className="me-2 text-burgundy fa-lg" />
                            <h2 className="h4 text-uppercase text-navy">Objectives</h2>
                          </div>
                          <div className="card-body">
                            <ol>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>to develop a Christ-like character;</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>to experience the joy and satisfaction of doing things well;</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>to express their love for Jesus in a natural way;</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>to learn good sportsmanship and strengthen their ability to get along with others;</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>to discover their God-given abilities and to learn how to use them to benefit self and serve others;</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>to discover God's world;</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>to improve their understanding of what makes families strong;</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>to develop parental support for the training of children.</span></li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id='Pledge-Law' className='row py-3 d-flex'>
                      <div className='col-md-6 d-flex mb-3'>
                        <div className="card flex-fill">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center">
                            <FontAwesomeIcon icon={faCross} className="me-2 text-burgundy fa-lg" />
                            <h2 className="h4 text-uppercase text-navy">Pledge</h2>
                          </div>
                          <div className="card-body">
                            <p>Because Jesus loves me, I can always do my best.</p>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6 d-flex mb-3'>
                        <div className="card flex-fill">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center">
                            <FontAwesomeIcon icon={faListAlt} className="me-2 text-burgundy fa-lg" />
                            <h2 className="h4 text-uppercase text-navy">Law</h2>
                          </div>
                          <div className="card-body">
                            <ul>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be obedient</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be pure</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be true</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be kind</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be respectful</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be attentive</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be helpful</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be cheerful</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be thoughtful</span></li>
                              <li><FontAwesomeIcon icon={faCheck} className="me-2 text-burgundy" /><span>Be reverent</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id='Uniform' className='row py-3'>
                      <div className='col-md-12 mb-3'>
                        <div className="card">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faTshirt} className="me-2 text-burgundy fa-lg" />
                            <h2 className="h4 text-uppercase text-navy text-center py-3">Uniform</h2>
                          </div>
                          <div className="card-body">
                            <div className='row'>
                              <div className='col-md-6 mb-3'>
                                <h6 className="h6 text-uppercase">Class "A" Uniform for the Adventurer boy and girl</h6>
                                <table className="table table-sm white-text">
                                  <tbody>
                                    <tr>
                                      <td>GIRLS</td>
                                      <td>Navy blue pinafore or skirt, white blouse (shirt type blouse with short sleeves).</td>
                                    </tr>
                                    <tr>
                                      <td>BOYS</td>
                                      <td>Navy blue pants, white shirt with short sleeves and regular collar.</td>
                                    </tr>
                                    <tr>
                                      <td>BOYS/GIRLS</td>
                                      <td>Shoes: uniform within club; Navy blue or white socks: uniform within club.</td>
                                    </tr>
                                    <tr>
                                      <td>SCARF</td>
                                      <td>Adventurer scarf is part of uniform and worn with Adventurer slide. Staff members who are Master Guides may wear their Master Guide scarves.</td>
                                    </tr>
                                    <tr>
                                      <td>SASH</td>
                                      <td>Sash is navy blue and worn at appropriate times. AY honors are NOT to be worn on the Adventurer sash; only Adventurer awards are to be worn on the sash.</td>
                                    </tr>
                                    <tr>
                                      <td>EMBLEMS</td>
                                      <td>Adventurer patch, place on right sleeve; Adventurer World, place on left sleeve; Club Crescent, place on right sleeve above Adventurer patch; Adventurer awards, worn on the sash; Class Pins, worn on shirt or blouse pocket.</td>
                                    </tr>
                                    <tr>
                                      <td>JEWELRY</td>
                                      <td>Adventurer members should not wear jewelry.</td>
                                    </tr>
                                    <tr>
                                      <td>HATS</td>
                                      <td>There is no hat for Adventurers in Class A uniform.</td>
                                    </tr>
                                    <tr>
                                      <td>STAFF</td>
                                      <td>Staff members should wear the Adventurer Class “A” Uniform that is navy blue and part of the uniform.</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className='col-md-6 mb-3'>
                                <h6 className="h6 text-uppercase">Class "A" Uniform for Adventurer Staff</h6>
                                <div className="table-responsive mb-3">
                                  <table className="table white-text table-sm">
                                    <tbody>
                                      <tr>
                                        <td>LADIES</td>
                                        <td>Navy blue A-line (slim) skirt; White blouse (shirt type blouse with short sleeves).</td>
                                      </tr>
                                      <tr>
                                        <td>MEN</td>
                                        <td>Navy blue pants, white shirt (with short sleeves and regular collar).</td>
                                      </tr>
                                      <tr>
                                        <td>MEN/LADIES</td>
                                        <td>Shoes: uniform within club; belt: uniform within club; Navy blue tie (optional for men).</td>
                                      </tr>
                                      <tr>
                                        <td>SCARF</td>
                                        <td>The Adventurer scarf is part of the uniform and worn with an Adventurer slide. Staff members who are Master Guides may wear their Master Guide scarves.</td>
                                      </tr>
                                      <tr>
                                        <td>SASH</td>
                                        <td>Sash is navy blue and worn at appropriate times. AY honors are NOT to be worn on the Adventurer sash; only Adventurer awards are to be worn on the sash.</td>
                                      </tr>
                                      <tr>
                                        <td>EMBLEMS</td>
                                        <td>Adventurer Patch, place on right sleeve; Adventurer World, place on left sleeve; Club Crescent, place on right sleeve above Adventurer patch; Adventurer Awards, worn on the sash; Class Pins, worn on shirt or blouse pocket.</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div className='col-md-12 mb-3'>
                                <h2 className="h4 mb-3 text-uppercase">Field Uniform (Type B)</h2>
                                <p>Field uniform for the Adventurers and staff is a T-shirt and blue jeans. Each club may create its own design for field uniform T-shirts and sweatshirts. A few suggestions for designs on T-shirts are:</p>
                                <ul>
                                  <li>Adventurer logo</li>
                                  <li>Local club logo</li>
                                  <li>Adventurer and local club logo</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id='Insignia-Placement' className="row py-3">
                      <div className='col-md-12 mb-3'>
                        <div className="card">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 text-burgundy fa-lg" />
                            <h2 className="h4 text-uppercase text-navy py-3">INSIGNIA PLACEMENT</h2>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-3 mb-3">
                                <h3 className="h5" id="left-sleeve">LEFT SLEEVE</h3>
                                <p>The Adventurer World emblem is to be worn on left sleeve 1½” below the shoulder seam.</p>
                              </div>
                              <div className="col-md-3 mb-3">
                                <img className="img-fluid mb-3" src={LeftSleeve} alt="Left Sleeve" />
                              </div>

                              <div className="col-md-3 mb-3">
                                <h3 className="h5" id="right-sleeve">RIGHT SLEEVE</h3>
                                <p>The Adventurer Club Name Crescent is to be worn ½” below the shoulder seam. The Adventurer Club Emblem is to be worn 2½” down from the shoulder seam.</p>
                              </div>
                              <div className="col-md-3 mb-3">
                                <img className="img-fluid mb-3" src={RightSleeve} alt="Right Sleeve" />
                              </div>

                              <div className='col-md-4 mb-3'>
                                <h2 className="h4 mb-3 text-uppercase">SASH</h2>
                                <p>Adventurer awards and class pins, only. If you have earned any AY/Pathfinder Honors you are not to place them on your sash.</p>
                              </div>

                              <div className='col-md-4 mb-3'>
                                <h2 className="h4 mb-3" id="name-tag">NAME TAG</h2>
                                <p>Your name tag is to be centered over the right pocket or its equivalent position on the sash or blouse.</p>
                              </div>

                              <div className='col-md-4 mb-3'>
                                <h2 className="h4 mb-3" id="staff">STAFF</h2>
                                <p>Your staff rank stripe goes between the club name crescent and the Adventurer Club Emblem. Move the Adventurer Club Emblem down an extra inch.</p>
                                <p> You are to wear an Adventurer sash (not an AY/Pathfinder sash) even if you are a Master Guide. </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id='Song' className='row py-3'>
                      <div className='col-12 mb-3'>
                        <div className="card">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faMusic} className="me-2 text-burgundy fa-lg" />
                            <h2 className="h4 text-uppercase text-navy py-3">Song</h2>
                          </div>
                          <div className="card-body">
                            <center>
                            <img className="img-fluid mb-3" src={adventurersSong} alt="Adventurer Song" />
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </article>
            </section>

          </div>
        </main>

        <Footer />
      </div>

      {/* Dark Overlay */}
      <div className="overlay-background" style={{ backgroundImage: `url(${planningCommitImage})` }}></div>
    </>
  );
}

export default Adventurers;
