import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from 'react-router-dom'
import axios from 'axios'
import backgroundImage from "./images/wc logo2.webp";
import $ from 'jquery'
import Header from './Header';
import Footer from './Footer';
//import 'datatables.net-dt/js/dataTables.dataTables'

import 'datatables.net-dt/js/dataTables.dataTables'

import {
    MDBIcons,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon
  } from 'mdb-react-ui-kit';

const SYLs = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  /*********The Counter To Refresh The Data Info Views Starts Here*****************/
  let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 360000
  setInterval(() => {
    setCount(count + 1)
  }, delay)
  /*********The Counter To Refresh The Data Info Views Ends Here**********************/

  let [handleLoader, setHandleLoader] = useState('')
  const [DBView, setDBView] = useState([])


  const [iconsActive, setIconsActive] = useState('tab1');

  const handleIconsClick = value => {
    if (value === iconsActive) {
      return;
    }

    setIconsActive(value);
  };

  useEffect(() => {
    //alert('ID' + secondUserSystemSetupBranchID)
    setHandleLoader('Loading')
    var formData = new FormData(this)
    formData.append('apiType', 'getViewSYLs')
    axios({
      method: 'post',
      url: `${server_app_api_php}/server/index.php`,
      //url: `http://localhost/react/kanchy/src/server/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleLoader('')
      setDBView(response.data)

      //alert(response.data)

      setTimeout(function () {
        $('#table').DataTable({
          retrieve: true, // this hides the errors
          pageLength: 5,
          lengthMenu: [5,10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
          layout: {
            topEnd: {
                search: {
                    text: '',
                    placeholder: 'Search here'
                }
            }
        }
        })
      }, 2000)
    })//.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  return (
    <>

<div>
<Header />

  <main>

  <div class="container">

  <div hidden={handleLoader ===''}>
        <div class="loader d-flex justify-content-center">          
          <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
          <span class=''>Loading...</span>
</div>
        </div>
        </div>

  <section id="news" class="mt-5 mb-4">

          <h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 pt-4 wow fadeIn" data-wow-delay=".2s">INVESTED SENIOR YOUTH LEADERS</h2>
<hr class=" mb-4" />

<MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
            <MDBIcon fas icon='grip-horizontal' className='me-2' /> Grid View
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
            <MDBIcon fas icon='table' className='me-2' /> Table View
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

    
      <MDBTabsContent>
        <MDBTabsPane open={iconsActive === 'tab1'}>
    
                   <div class='row' >
                    {DBView.map(val => {
                      return (
                        <>
                          <div class='col-xl-4 col-md-6 mb-4'>
                            {/*<!-- Panel -->*/}
                            <div class='card'>
                              <div
                                class='card-header text-uppercase white-text info-color'>
                                {val.Name}
                              </div>

                              <div class='row mt-1'>
                                <div class='col-md-12 col-12 text-right pr-4'>
                                  <div class='font-small grey-text'>
                                  Investing Pastor :
                                    <span class='ml-1 mt-1 mb-1 font-weight-bold black-text'>
                                      {val.stateTheInvestingPastorSyl}
                                    </span>
                                  </div>
                                  <div class='mt-1 font-small grey-text'>
                                    Year Invested :
                                    <span class='ml-1 mt-1 font-weight-bold black-text'>
                                      {val.stateTheYearYouWereInvestedSyl}
                                    </span>
                                  </div>
                                  <div class='mt-1 font-small grey-text'>
                                    District :
                                    <span class='ml-1 mt-1 font-weight-bold black-text'>
                                      {val.district}
                                    </span>
                                  </div>
                                  <div class='mt-1 font-small grey-text'>
                                    Church :
                                    <span class='ml-1 mt-1 font-weight-bold black-text'>
                                      {val.church}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/*<!-- Card content -->*/}
                            </div>
                            {/*<!-- Panel -->*/}
                          </div>
                        </>
                      )
                    })}
                    {/*<!-- Grid column -->*/}
                  </div>
                  {/* <!-- Grid row -->*/}


        {/*</div>*/}
<hr class="mb-5" />


</MDBTabsPane >
        <MDBTabsPane open={iconsActive === 'tab2'} >

        <table
                        id='table'
                        class='table table-striped table-bordered table-hover table-sm nowrap display'
                        width='100%'
                      >
                        <thead class='form-header text-uppercase ocean blue-gradient'>
                          <tr>
                            <th>S/N</th>
                            <th>Name</th>
                            <th>Year Invested</th>
                            <th>Investing Pastor</th>
                            <th>District</th>
                            <th>Church</th>
                          </tr>
                        </thead>
                        <tbody>
                          {DBView.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val.Name}</td>
                                <td>{val.stateTheYearYouWereInvestedSyl}</td>
                                <td>{val.stateTheInvestingPastorSyl}</td>
                                <td>{val.district}</td>
                                <td>{val.church}</td>

                              </tr>
                            )
                          })}
                        </tbody>
                        
                      </table>

        </MDBTabsPane>
      </MDBTabsContent>

</section>
</div>


 

  </main>
 
 
  <Footer />
        
      </div>
    </>
  )
}

export default SYLs
