import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from 'react-router-dom'
import axios from 'axios'
import backgroundImage from "./images/wc logo2.webp";
import Header from './Header';
import Footer from './Footer';

const Home = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  /*********The Counter To Refresh The Data Info Views Starts Here*****************/
  let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 360000
  setInterval(() => {
    setCount(count + 1)
  }, delay)
  /*********The Counter To Refresh The Data Info Views Ends Here**********************/

  const [showFullContent, setShowFullContent] = useState(false);

  const content = `this the brief news, of which if we want to read the whole news item, we need to click on view or see more button. after reading, we can also click on view less for us to hide the unwanted content and manage the screen well`;

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <>

<div>
<Header />
    {/*
    <div id="home" class="view jarallax" data-jarallax='{"speed": 0.2}'
   
      style={{  
  backgroundImage: "url(" + 'https://mdbootstrap.com/img/Photos/Others/images/67.jpg' + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}
>
*/}

 <div id="home" class="view jarallax" data-jarallax='{"speed": 0.2}'
   
      style={{
        //src={server_app_api_php+'/images/mutinta muyeeka.jpg'}
  backgroundImage: 'url('+server_app_api_php+'/resources/images/wc%20logo2.webp)' ,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}
>

<div className="mask rgba-black-strong">
  <div className="container h-100 d-flex justify-content-center align-items-center">
    <div className="row smooth-scroll">
      <div className="col-md-12 white-text text-center">
        <div className="wow fadeInDown mt-5" data-wow-delay="0.2s">
          <h4 className="display-3 font-weight-bold mb-2 mt-5 mt-md-3 mt-lg-4">WOODLANDS CONFERENCE</h4>
          <hr className="hr-light" />
          <h3 className="subtext-header mt-4 mb-0 font-weight-bold">ADVENTIST YOUTH MINISTRIES</h3>
        </div>
        <div className="row text-center justify-content-center">
          <a href="Adventurers" data-offset="100" className="btn btn wow fadeInLeft col-md-3 col-10 white-text mb-2" data-wow-delay="0.2s" style={{ backgroundColor: '#800020' }}>
            Adventurers
            <span className="icon-adventurer"></span>
          </a>
          <a href="Pathfinders" data-offset="100" className="btn btn-yellow wow fadeInRight col-md-2 col-10 mb-2" data-wow-delay="0.2s">Pathfinders</a>
          <a href="Ambassadors" data-offset="100" className="btn btn-info wow fadeInLeft col-md-2 col-10 mb-2" data-wow-delay="0.2s">Ambassadors</a>
          <a href="YoungAdults" data-offset="100" className="btn btn wow fadeInRight col-md-2 col-10 white-text mb-2" style={{ backgroundColor: '#000030' }} data-wow-delay="0.2s">Young Adults</a>
          <a href="PCM" data-offset="100" className="btn btn-purple wow fadeInLeft col-md-2 col-10 mb-2" data-wow-delay="0.2s">PCM</a>
        </div>
      </div>
    </div>
  </div>
</div>

    
    </div>


  

  <main>

  <div class="container">

<section id="testimonials" class="mb-5">


  <h2 class="text-center mb-5 my-5 pt-5 pb-4 font-weight-bold wow fadeIn" data-wow-delay="0.2s">ADVENTIST YOUTH MINISTRIES CONFERENCE EXECUTIVE MEMBERS</h2>


  <div className="row">
  <div id="multi-item-example" className="carousel testimonial-carousel slide carousel-multi-item wow fadeIn" data-ride="carousel">
    <div className="controls-top">
      <a className="btn-floating light-blue darken-4" href="#multi-item-example" data-slide="prev">
        <i className="fas fa-chevron-left"></i>
      </a>
      <a className="btn-floating light-blue darken-4" href="#multi-item-example" data-slide="next">
        <i className="fas fa-chevron-right"></i>
      </a>
    </div>
    <ol className="carousel-indicators">
      <li data-target="#multi-item-example" data-slide-to="0" className="active light-blue darken-4"></li>
      <li data-target="#multi-item-example" data-slide-to="1" className="light-blue darken-4"></li>
      <li data-target="#multi-item-example" data-slide-to="2" className="light-blue darken-4"></li>
      <li data-target="#multi-item-example" data-slide-to="3" className="light-blue darken-4"></li>
      <li data-target="#multi-item-example" data-slide-to="4" className="light-blue darken-4"></li>
    </ol>
    <div className="carousel-inner" role="listbox">
      <div className="carousel-item active">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/pastor mainza shimunzhila.jpeg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">Pastor Mainza Shimunzhila</h4>
              <p>Youth Director</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/lameck chimula.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Lameck Chimula</h4>
              <p>Chairperson</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/heather khulekeni.jpeg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Heather Hanene</h4>
              <p>Vice Chairperson</p>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/mutinta muyeeka.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Mutinta Muyeeka</h4>
              <p>Executive Secretary</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/moses shabuwe.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Moses Shabuwe</h4>
              <p>Vice Secretary</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/chimba mutale.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Chimba Mutale</h4>
              <p>Treasurer</p>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/nanalisa.jpeg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Nanalisa Bwani</h4>
              <p>Vice Treasurer</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/paulsen tembo.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Paulsen Tembo</h4>
              <p>Planning Chairperson</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/henry mweemba.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Henry Mweemba</h4>
              <p>Projects Coordinator</p>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/bruno nyambe.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Bruno Nyambe</h4>
              <p>Communications Chairperson</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/akufuna.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Akufuna</h4>
              <p>Parliamentarian</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/agripa kaanga.jpg`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Agripa Kaanga</h4>
              <p>Education And Standards Chairperson</p>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="testimonial text-center">
              <div className="avatar mx-auto mb-4">
                <img src={`${server_app_api_php}/resources/images/`} className="rounded-circle img-fluid" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
              </div>
              <h4 className="font-weight-bold">MG/SYL Beston Zulu</h4>
              <p>Chaplain</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


</section>

</div>


    
    <div class="streak streak-photo streak-md"
      style={{  
  //backgroundImage: "url(" + 'https://pos.kanchtech.com/wcayimages/wc%20logo.jpg' + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}
>
      
     {/* <div class="flex-center mask rgba-indigo-strong">*/}
     <div class="flex-center mask rgba-black-strong">
        <div class="text-center white-text">
          <h2 class="h2-responsive mb-3">
            <i class="fas fa-quote-left mr-3" aria-hidden="true"></i> 
            I can do all things through Christ who strengthens me.

            <i class="fas fa-quote-right ml-3" aria-hidden="true"></i>
          </h2>
          <h5 class="text-center font-italic mb-3" data-wow-delay="0.2s">~ Philippians 4:13 ~</h5>
        </div>
      </div>
    </div>

    <div class="container-fluid background-r">
   {/* <div className="container">
  {/* Section: News */}
  <section id="news" className="mt-5 mb-4">

    <h2 className="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 pt-4 wow fadeIn" data-wow-delay=".2s">News</h2>

    <hr className=" mb-4"/>

    {/* Third row */}
    <div className="row mb-5 pt-3 wow fadeIn" data-wow-delay=".4s">

      {/* Grid column */}
      <div className="col-lg-4 mb-md-0 mb-4">
        {/* Image */}
        <div className="view overlay z-depth-1">
          <img src={server_app_api_php+'/resources/images/news1.jpg'} className="img-fluid z-depth-1-half" alt="" style={{width:'100%',height:'300px'}} />                  
          <div className="mask rgba-white-slight"></div>
        </div>
        {/* Image */}
      </div>
      {/* Grid column */}
      {/* Grid column */}
      <div className="col-lg-7 ml-lg-4 mb-5">
        {/* Excerpt */}
        <h4 className="text-uppercase mb-4"><a href="#!" className="black-text-2 font-weight-bold pb-3">| Lake Road Mission District
And shantumbu SDA Church during the recent conducted  Hiking in Shantumbu Mountains</a></h4>
        <p className="grey-text-3 font-thin" align="justify">
        Brief News Here

        {showFullContent ? content : `${content.slice(0, 50)}...`}
              <span onClick={toggleContent} style={{ cursor: 'pointer', color: 'blue' }}>
                {showFullContent ? ' Read less' : ' Read more'}
              </span>
        </p>
        {/* Excerpt */}
      </div>
      {/* Grid column */}
</div>
      <hr class="mb-5" />

      <div className="row mb-5 pt-3 wow fadeIn" data-wow-delay=".4s">
      {/* Grid column */}
      <div className="col-lg-4 mb-md-0 mb-4">
        {/* Image */}
        <div className="view overlay z-depth-1">
          <img src={server_app_api_php+'/resources/images/news2.jpg'} className="img-fluid z-depth-1-half" alt="" style={{width:'100%',height:'300px'}} />                  
          <div className="mask rgba-white-slight"></div>
        </div>
        {/* Image */}
      </div>
      {/* Grid column */}
      {/* Grid column */}
      <div className="col-lg-7 ml-lg-4 mb-5">
        {/* Excerpt */}
        <h4 className="text-uppercase mb-4"><a href="#!" className="black-text-2 font-weight-bold pb-3">| We join our brethren from Northern Zambia Union Conference -Adventist Youth Ministries  💔😭
May God comfort the families and Church during this trying time.</a></h4>
        <p className="grey-text-3 font-thin" align="justify">
        Brief News Here

        {showFullContent ? content : `${content.slice(0, 50)}...`}
              <span onClick={toggleContent} style={{ cursor: 'pointer', color: 'blue' }}>
                {showFullContent ? ' Read less' : ' Read more'}
              </span>
        </p>
        {/* Excerpt */}
      </div>
      {/* Grid column */}
</div>
      <hr class="mb-5" />

      <div className="row mb-5 pt-3 wow fadeIn" data-wow-delay=".4s">
      {/* Grid column */}
      <div className="col-lg-4 mb-md-0 mb-4">
        {/* Image */}
        <div className="view overlay z-depth-1">
          <img src={server_app_api_php+'/resources/images/news3.jpg'} className="img-fluid z-depth-1-half" alt="" style={{width:'100%',height:'300px'}} />                  
          <div className="mask rgba-white-slight"></div>
        </div>
        {/* Image */}
      </div>
      {/* Grid column */}
      {/* Grid column */}
      <div className="col-lg-7 ml-lg-4 mb-5">
        {/* Excerpt */}
        <h4 className="text-uppercase mb-4"><a href="#!" className="black-text-2 font-weight-bold pb-3">| Highlights of Pathfinder hiking hosted by Kafue Central and accompanied by 
                  Greenfield and Nangongwe churches from kafue.</a></h4>
        <p className="grey-text-3 font-thin" align="justify">
        Brief News Here

        {showFullContent ? content : `${content.slice(0, 50)}...`}
              <span onClick={toggleContent} style={{ cursor: 'pointer', color: 'blue' }}>
                {showFullContent ? ' Read less' : ' Read more'}
              </span>
        </p>
        {/* Excerpt */}
      </div>
      {/* Grid column */}
</div>
      <hr class="mb-5" />

      <div className="row mb-5 pt-3 wow fadeIn" data-wow-delay=".4s">
      {/* Grid column */}
      <div className="col-lg-4 mb-md-0 mb-4">
        {/* Image */}
        <div className="view overlay z-depth-1">
          <img src={server_app_api_php+'/resources/images/news4.jpg'} className="img-fluid z-depth-1-half" alt="" style={{width:'100%',height:'300px'}} />                  
          <div className="mask rgba-white-slight"></div>
        </div>
        {/* Image */}
      </div>
      {/* Grid column */}
      {/* Grid column */}
      <div className="col-lg-7 ml-lg-4 mb-5">
        {/* Excerpt */}
        <h4 className="text-uppercase mb-4"><a href="#!" className="black-text-2 font-weight-bold pb-3">| Highlights of Woodlands Central SDA Youth camp.</a></h4>
        <p className="grey-text-3 font-thin" align="justify">
        Brief News Here

        {showFullContent ? content : `${content.slice(0, 50)}...`}
              <span onClick={toggleContent} style={{ cursor: 'pointer', color: 'blue' }}>
                {showFullContent ? ' Read less' : ' Read more'}
              </span>
        </p>
        {/* Excerpt */}
      </div>
      {/* Grid column */}

      <hr class="mb-5" />

    </div>
    {/* Third row */}

  </section>

  
</div>


  </main>
 
 
  <Footer />
        
      </div>
    </>
  )
}

export default Home
