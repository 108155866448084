import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Ambassadors.css'; // Assuming you have a CSS file for the custom styles
import planningCommitImage from './images/young adults.jpg';

const Ambassadors = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="overlay">
                <Header />
                <hr className='mt-5 mb-5' />
                <main>
                    <div className="container mt-5">
                        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed scrolling-navbar">
                            <div className="container">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav"
                                    aria-controls="navbarNav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={toggleNavbar}
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Philosophy-And-Objectives">Philosophy & Objectives</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Membership-And-Programming-Fundamentals" title='Membership & Programming Fundamentals'>
                                                Membership & Programming Fundamentals</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Mission-Aim-And-Motto" title='Mission, Aim, and Motto'>Mission</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="Adventurers" title='Adventurers Ministries'>Adventurers</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="Pathfinders" title='Pathfinders Ministries'>Pathfinders</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="YoungAdults" title='Young Adults Ministries'>Young Adults</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="PCM" title='Public Campus Ministries'>PCM</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        <section className="container-fluid mt-5">
                            <article className="c-article l-grid-item l-grid-item--l--4-col l-grid-item--xxl--3-col post-1839 page type-page status-publish format-standard hentry">
                                <div className="c-article__body mt-5">
                                    <div className="text u-spacing">
                                        <div id='Philosophy-And-Objectives' className='row py-5'>
                                            <div className='col-md-6 mb-3'>
                                                <div className="card uniform-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <i className="fas fa-lightbulb"></i> PHILOSOPHY
                                                        </h5>
                                                        <p>The Seventh-day Adventist Church is committed to understanding and training its youth for leadership and service to humanity.</p>
                                                        <p>Considering its newness and the well entrenched clubs already existing, perhaps what it is not—should be mentioned first. It is not an extension of the Pathfinder Club or as some might envision—Maxi-Pathfinders. It does not follow the same programmatic format as that of Pathfinders nor have other external similarities. It does not use the same emblems, tokens and other devices as used by the Pathfinder Club except as a local club chooses to get involved in Honors or Class levels for which only those emblems directly related would be presented upon completion of given requirements. It is also not an AY Society, nor does it necessarily fulfill the role of an AYS from a Friday night or Sabbath afternoon program perspective. Over the past many decades the AYS has been defined by its traditional role as an organization that is primarily focused on providing a formal youth program in the church on Sabbaths and attended by youth and other church members of various ages but generally led by youth.</p>
                                                        <p>The Ambassador Club exists primarily to provide Senior Youth with an organized structured system that will promote active involvement of its membership in their church (local and global) as well as their community, while providing them with opportunities to discover their God-given talents and life vocation.</p>
                                                        <p>The specific focus of a local club may be determined by local leadership in consultation with the youth wishing to participate as long as the overall mission of the organization is not placed in jeopardy. The focus selected may vary during any given year, may develop over several years based on cycles of training, or may function concurrently for different/overlapping groups meeting their felt needs.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <div className="card uniform-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <i className="fas fa-bullseye"></i> Objectives
                                                        </h5>
                                                        <p>The following objectives will be achieved as club leaders explore and develop their programs based on the seven programming fundamentals.</p>
                                                        <ol>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Help Senior Youth to realize that God and His church love them and appreciate the implementation of their talents for the fulfilling of their mutual gospel commission as established in Matt. 28: 18-20 and Acts 1:8. They will find fulfillment in their life-style as they share their beliefs with those whom God brings to them.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Encourage Senior Youth to discover their God-given talents and to use their gifts and abilities to fulfill God’s expectations for them.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Inspire the Senior Youth to give personal expression of their love for God by uniting them in various outreach activities.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Make the number one priority of Ambassador programming to be the personal salvation of each and every youth who is a member.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Build into the youth’s lifestyle an appreciation, understanding and love for God’s creation through a safely planned program of adventure and discovery. They will find their fellowship with God to be more meaningful as they have the opportunity to experience that sense of wonder and worship as nature unfolds its deepest spiritual secrets as outlined in Romans 1:19, 20.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Teach Senior Youth specific vocational skills and hobbies that will provide them with purpose and employment opportunities.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Encourage the youth to develop and maintain physical fitness through an active, energetic, drug free lifestyle.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Provide youth with opportunities to develop and demonstrate their leadership abilities. They will strengthen their resolve to develop and maintain appropriate internal discipline and apply their skills of resourcefulness and understanding of the processes of group dynamics.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Provide ample opportunities for youth of both sexes to interact in wholesome activities that will lead to and strengthen life-long committed relationships.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id='Membership-And-Programming-Fundamentals' className='row py-5'>
                                            <div className='col-md-6 mb-3'>
                                                <div className="card uniform-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <i className="fas fa-user-friends"></i> Membership
                                                        </h5>
                                                        <p>Any youth age 16 up through the mid-twenties who choose to live by the principles and guidelines of the Seventh-day Adventist Church. For definition purposes, the Youth Department defines Junior Youth Ministry as focusing on those under the age of 16 (another way of wording it would be ages 15 and under). Senior Youth are those who are ages 16-30+, divided in two Ministries: Ambassadors, ages 16-21 and Young Adults, ages 22 to 30+. This club’s focus is primarily for those within the Senior Youth category; however, the upper age limit is left more open because in many areas there are limited possibilities for young adults, especially those who are single to connect with other young adults and this club could serve as a connecting point for those who are older than 21.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <div className="card uniform-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <i className="fas fa-cogs"></i> Programming Fundamentals
                                                        </h5>
                                                        <p>The 7 Foundations of the Ambassador Programming are:</p>
                                                        <ol>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Leadership development. Curricula such as the Master Guide, AY Leadership, Small Group Leadership and other age appropriate and well established schemes can be considered.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Community outreach development through service and emergency preparedness training.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> A Christ-centered discipleship plan.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> A personal and small group based mission lifestyle.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Lifestyle vocational/career development through specialized multi-vocational training and advanced honor level.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Friendship and relationship skill development.</li>
                                                            <li><i className="fas fa-check sky-blue-color"></i> Character and personality development/awareness through outdoor/high adventure programming.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id='Mission-Aim-And-Motto' className='row mb-2 py-5'>
                                            <div className='col-12 text-center bg-dark  mb-3 sky-blue-color py-3'>
                                                <span className="h2 text-uppercase py-5">Mission, Aim, and Motto</span>
                                            </div>

                                            <div className='col-md-4 mb-3'>
                                                <div className="card uniform-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <i className="fas fa-cross"></i> Mission Statement
                                                        </h5>
                                                        <p>The Ambassador Group is an organization of the Seventh-day Adventist Church dedicated to meeting the spiritual, social, and lifestyle needs of youth ages 16-20’s by challenging them to experiencing and sharing a personal relationship with Christ, developing a lifestyle fitting their belief system and vocational interest, and providing them with an adequate venue for wholesome development of lifelong friendships.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-4 mb-3'>
                                                <div className="card uniform-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <i className="fas fa-bullseye"></i> Aim
                                                        </h5>
                                                        <p>The Advent Message to All the World in My Generation</p>
                                                        <p>My relationship to Jesus Christ is of such a nature that it compels me to share with any who will receive it, the gospel—the good news of His soon return.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-4 mb-3'>
                                                <div className="card uniform-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <i className="fas fa-heart"></i> Motto
                                                        </h5>
                                                        <p>The Love of Christ Compels Me.</p>
                                                        <p>I am drawn to Him by His exemplary life, the symbolic act of His crucifixion, His conquering resurrection, and His promise of an earth made new in the pattern of the original creation. The closer I find myself to Him, the closer I find myself identifying with the needs of my fellow human beings.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>
                </main>
                <Footer />
                </div>

                <div className="overlay-background" style={{ backgroundImage: `url(${planningCommitImage})` }}>
            </div>
        </>
    );
}

export default Ambassadors;
