const Footer = () => {
    return (
        <>
    
    <footer class="page-footer text-center text-md-left mdb-color darken-3">

  
<div class="container-fluid">

{/*<!--First row-->*/}
  <div class="row " data-wow-delay="0.2s">
   {/* <!--First column-->*/}
    <div class="col-md-12 text-center mb-3 mt-3">
      <h2 class="mt-3 mb-3">WOODLANDS CONFERENCE - ADVENTIST YOUTH MINISTRIES</h2>
    </div>
   {/* <!--First column-->*/}
    <hr class="w-100 mt-1 mb-5" />
  </div>
 {/* <!--First row-->*/}
  
<div class="container mb-1">
    
    <div class="row">

     
      <div class="col-xl-4 col-lg-4 pt-1 pb-1">
        
        <h5 class="text-uppercase mb-3 font-weight-bold">WC Socials</h5>

        <p></p>

        <p></p>

        <div class="footer-socials mt-4">

         
          <a type="button" class="btn-floating btn-blue-2 ">
            <i class="fab fa-facebook-f"></i>
          </a>
          
          <a type="button" class="btn-floating btn-blue-2 ">
            <i class="fab fa-twitter"></i>
          </a> 
          
          <a type="button" class="btn-floating btn-blue-2 ">
            <i class="fab fa-instagram"></i>
          </a>            
          
          <a type="button" class="btn-floating btn-blue-2 ">
            <i class="fab fa-whatsapp"></i>
          </a>

        </div>
      </div>
     

      <hr class="w-100 clearfix d-lg-none" />

     
      <div class="col-xl-3 ml-lg-auto col-lg-4 col-md-6 mt-1 mb-1">
        
        <h5 class="text-uppercase mb-3 font-weight-bold">CONTACT</h5>

        
       
        <p>
          <i class="fas fa-home pr-1"></i> Lusaka, Woodlands, Zambia</p>
        <p>
          <i class="fas fa-envelope pr-1"></i> email@wcay.com</p>
        <p>
          <i class="fas fa-phone pr-1"></i> +260 966749476</p>
      </div>
      

      <hr class="w-100 clearfix d-md-none" />

     
      <div class="col-xl-3 ml-lg-auto col-lg-4 col-md-6 mt-1 mb-1">
       
        <h5 class="text-uppercase mb-3 font-weight-bold">Important Links</h5>

        <ul class="footer-posts list-unstyled">
          <li>
            <a>GC AY</a>
            <span>
              <p class="grey-text"><a>GC AY facebook</a></p>
            </span>
          </li>
          <li>
            <a>SID AY</a>
            <span>
              <p class="grey-text"><a>SID AY facebook</a></p>
            </span>
          </li>
          <li>
            <a>SZBU AY facebook</a>
          </li>
          <li>
            <a>Woodlands Conference AY facebook</a>
           
          </li>
        </ul>

      </div>
    

    </div>
   

  </div>

</div>

<div class="footer-copyright py-3 text-center">
  <div class="container-fluid">
    © 2024 Copyright: <a href="" target="_blank"> Woodlands Conference AY Ministry </a>
  </div>
</div>

</footer>  
    
    </>
        );
    
    }
    export default Footer