import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faCalendarAlt, faBriefcase, faUsers, faMoneyBillAlt, faChurch, faGraduationCap, faComments, faUserTie, faUser, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';

const HeaderAdmin = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    }

    return (
        <div className="dashboard">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="navbar-brand">WC</div>
                <button className="navbar-toggler" type="button" onClick={toggleMobileMenu}>
                    <span className="navbar-toggler-icon">
                        {showMobileMenu ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
                    </span>
                </button>
                <div className={`collapse navbar-collapse ${showMobileMenu ? 'show' : ''}`}>
                    <ul className="navbar-nav mr-auto">                        
                    
                        <li className="nav-item">
                            <Link className="nav-link" to="/director"><FontAwesomeIcon icon={faTachometerAlt} /> Dashboard</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/director"><FontAwesomeIcon icon={faUser} /> Director</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/chairperson"><FontAwesomeIcon icon={faUserTie} /> Chairperson</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/secretariat"><FontAwesomeIcon icon={faBriefcase} /> Secretariat</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Treasury"><FontAwesomeIcon icon={faMoneyBillAlt} /> Treasury</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/planning"><FontAwesomeIcon icon={faCalendarAlt} /> Planning</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Parliamentarian"><FontAwesomeIcon icon={faUsers} /> Parliamentarian</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/projects"><FontAwesomeIcon icon={faMoneyBillAlt} /> Projects</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/education"><FontAwesomeIcon icon={faGraduationCap} /> Education</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/communication"><FontAwesomeIcon icon={faComments} /> Communication</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/chaplaincy"><FontAwesomeIcon icon={faChurch} /> Chaplaincy</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Profile"><FontAwesomeIcon icon={faUser} /> Profile</Link>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* Dashboard content goes here */}
        </div>
    );
}

export default HeaderAdmin;
