import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faCalendarAlt, faBriefcase, faUsers, faMoneyBillAlt, faChurch, faGraduationCap, faComments, faUserTie, faUser } from '@fortawesome/free-solid-svg-icons';
import HeaderAdmin from './HeaderAdmin';

const Dashboard = () => {
   /* const [showMobileMenu, setShowMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    }
*/
    return (
        <div className="dashboard">
          <HeaderAdmin />
          {/* Dashboard content goes here */}
<div className="container">
    <h2>Welcome to Your Dashboard!</h2>
    <p>Explore various sections using the navigation menu on the left.</p>
    <div className="row mt-4">
        <div className="col-md-6 mb-2">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Recent Projects</h5>
                    <p className="card-text">View and manage your recent projects here.</p>
                    <a href="/projects" className="btn btn-primary">View Projects</a>
                </div>
            </div>
        </div>
        <div className="col-md-6 mb-2">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Finance Overview</h5>
                    <p className="card-text">Analyze your financial data and budgets.</p>
                    <a href="/finance" className="btn btn-primary">View Finance</a>
                </div>
            </div>
        </div>
    </div>
    <div className="row mt-4">
        <div className="col-md-6 mb-2">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Upcoming Events</h5>
                    <p className="card-text">Stay updated on upcoming events and meetings.</p>
                    <a href="/planning" className="btn btn-primary">View Events</a>
                </div>
            </div>
        </div>
        <div className="col-md-6 mb-2">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Team Members</h5>
                    <p className="card-text">Manage your team members and roles.</p>
                    <a href="/users" className="btn btn-primary">View Team</a>
                </div>
            </div>
        </div>
    </div>
</div>

            {/* Dashboard content Ends here */}
        </div>
    );
}

export default Dashboard;
