import logo from './logo.svg'
//import './App.css'
import { Routes, Route } from 'react-router-dom'

import Missing from './components/Missing'
import Layout from './components/Layout'
import Home from './components/Home'
import Books from './components/books'
import MGs from './components/MGs'
import SYLs from './components/SYLs'
import Adventurers from './components/Adventurers'
import Pathfinders from './components/Pathfinders'
import Ambassadors from './components/Ambassadors'
import YoungAdults from './components/YoungAdults'
import PCM from './components/PCM'
import Dashboard from './components/Dashboard'

function App () {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='*' element={<Missing />} />
        <Route path='/' element={<Home />} />
        <Route path='Books' element={<Books />} />
        <Route path='MGs' element={<MGs />} />
        <Route path='SYLs' element={<SYLs />} />
        <Route path='Adventurers' element={<Adventurers />} />
        <Route path='Pathfinders' element={<Pathfinders />} />
        <Route path='Ambassadors' element={<Ambassadors />} />
        <Route path='YoungAdults' element={<YoungAdults />} />
        <Route path='PCM' element={<PCM />} />
        <Route path='Dashboard' element={<Dashboard />} />
      </Route>
    </Routes>
  )
}

export default App
