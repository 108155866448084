import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faListAlt, faTshirt, faMapMarkerAlt, faMusic, 
  faCheck, faTasks, faUsers, faFlag, faGraduationCap, faMedal, faCheckCircle, 
  faBullseye, faHeart, faHandshake, faBalanceScale,
  faCircle, faShieldAlt, faGem, faChessKnight
} from '@fortawesome/free-solid-svg-icons'; // Import necessary FontAwesome icons for headers
import './Pathfinders.css'; // Import your CSS file for Pathfinders styling
import Pathfinder_logo from './images/Pathfinder_logo.jpeg';
import Pathfinder_Insignia_Positions_on_Shirt_no_tie from './images/Pathfinder-Insignia-Positions-on-Shirt-no-tie.jpg';
import Pathfinder_Insignia_Positions_on_Shirt_and_the_Sash_no_tie from './images/Pathfinder-Insignia-Positions-on-Shirt-and-the-Sash-no-tie.jpg';
import Pathfinder_Uniform_Right_Sleeve from './images/Pathfinder-Uniform-Right-Sleeve.jpg';
import Pathfinder_Uniform_Left_Sleeve from './images/Pathfinder-Uniform-Left-Sleeve.jpg';
import pathfinder_song from './images/pathfinder-song.jpg';
import Area_Pathfinder_Coordinator_Epaulette from './images/Area-Pathfinder-Coordinator-Epaulette.webp';
import Conference_Pathfinder_Director_Epaulette from './images/Conference-Pathfinder-Director-Epaulette.webp';
import Union_Pathfinder_Director_Epaulette from './images/Union-Pathfinder-Director-Epaulette.webp';
import Division_Pathfinder_Director_Epaulette from './images/Division-Pathfinder-Director-Epaulette.webp';
import General_Conference_Pathfinder_Director_Epaulette from './images/General-Conference-Pathfinder-Director-Epaulette.webp';
import planningCommitImage from './images/pathfinders.jpg'; // Importing the background image

const Pathfinders = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>

<div className="overlay">
    <Header />
    <hr class = 'mt-5 mb-5' />
      <main>
    
      <div class="container mt-5">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed scrolling-navbar">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#Philosophy-Objectives">Philosophy & Objectives</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Chart-Organization">Organization</a>
            </li> 
            <li className="nav-item">
              <a className="nav-link" href="#Membership-Requirements">Membership</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Aim-Motto-Pledge-Law" title='Aim-Motto-Pledge-Law'>Law</a>
            </li> 
            <li className="nav-item">
              <a className="nav-link" href="#Meaning-Of-The-Pathfinder-Club-Emblem">Emblem</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Uniform">Uniform</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Insignia">Insignia</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Song">Song</a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="Adventurers" title='Adventurers Ministries'>Adventurers</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="Ambassadors" title='Ambassadors Ministries'>Ambassadors</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="YoungAdults" title='Young Adults Ministries'>Young Adults</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="PCM" title='Public Campus Ministries'>PCM</a>
            </li>

          </ul>
        </div>
      </div>
    </nav>

    <section  className="container-fluid mt-5">
      <article className="c-article l-grid-item l-grid-item--l--4-col l-grid-item--xxl--3-col post-1839 page type-page status-publish format-standard hentry">
        <div className="c-article__body mt-5">
          <div className="text u-spacing">

          <div id='Philosophy-Objectives' className='row mt-5 py-5 d-flex'>
            <div className='col-md-5 d-flex mb-3'>
            <div className="card flex-fill">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center">
                            <FontAwesomeIcon icon={faCross} className="me-2 text-burgundy fa-lg" />
            <h2 className="h5 text-uppercase py-0 gold-color" >PHILOSOPHY </h2>
            </div>
           <div className="card-body">
<p>The Seventh-day Adventist Church is committed to understanding young people and training its youth for leadership 
    and service to humanity.The Pathfinder Club is a church-centered spiritual-recreational-activity program 
    designed for young people 10 to 15 years of age. Pathfindering appeals to this age group because its program features
     activities that meet their needs and interests. Much of the Pathfinder Club program is built around physical action. 
     This is because youth from 10 to 15 years of age are in a fast-growing physical stage of development. It is filled 
     with action, adventure, challenge, group activities, and provides opportunities for the development of new attitudes
      and skills that produce personal growth, team or community spirit and a sense of loyalty and respect for God, His 
      Creation, and His church. While the Pathfinder Club exists primarily for youth, one of its basic purposes is to 
      also bring together parents and church members through active involvement with the club and its members. Here the 
      so-called generation gap disappears as young and old worship, work, and play together in a bond of common 
      experience. Meaningful relationships are forged as leaders and counselors join&nbsp;with Pathfinders in sharing, 
      building confidence, and working together.The whole philosophy of Pathfindering is built on the premise that 
      &#8220;children learn best by example, rather than precept.&#8221; As they see leaders and parents model spiritual 
      and social values, they too will aspire to develop high moral principles, loving and caring attitudes, and 
      determination to excel in all their various pursuits.Young people learn most effectively in a positive, happy,
       and secure atmosphere. The attitude of the club leaders is therefore a vital ingredient in guaranteeing the 
       success and effectiveness of this ministry to youth. A failure to listen to and understand the needs of the young 
       people will only erect barriers to real spiritual growth and development and may prove to be a contributing factor
        in making the church and its mission unattractive to the youth.</p>
</div>
</div>
</div>

<div className='col-md-7 d-flex mb-3'>
  <div class='row'>


<div className="card flex-fill">
                          <div className="card-header bg-transparent border-0 d-flex align-items-center">
                           
                            <div class='col-md-12'>
                            <FontAwesomeIcon icon={faCross} className="me-2 text-burgundy fa-lg" />
<span class="h5 text-uppercase py-5 text-right gold-color">Objectives</span>
</div>
            </div>
           <div className="card-body">

<p>This philosophy is an integral part of the club. The Pathfinder Club curriculum of six classes and nearly 250 Honors 
    lies at the heart of the program. The following objectives can be achieved as the club leaders seek to fulfill 
    these.</p>
<ol>
  <div class='row'>
  <div className='col-md-6'>
<li>Help the young people to understand that God and His church love them, care for them, and appreciate them. As
 Pathfinders are accepted and affirmed they will begin to appreciate the love of God revealed through the church and its
  ministry, and feel a need to be more committed to and involved with its program.</li>
<li>Encourage Pathfinders to discover their own God-given potential and to use their gifts and abilities to fulfill 
    God&#8217;s expectations for them and the part they can play in the great plan of salvation.</li>
<li>Inspire young people to give personal expression of their love for God by uniting them together in various 
        outreach activities.</li>
<li>Make the number one priority of your club program the personal salvation of every Pathfinder. The Pathfinder age 
    is a time when many decisions are being made that will affect the youth&#8217;s future relationships and his or 
    her own personal development. The peak time for discovering and making a relationship with God seems to be around 
    12 years of age.</li>
    
<li>Build into a Pathfinder&#8217;s life a healthy appreciation and love for God&#8217;s 
        creation by enjoying outdoor activity (campouts, nature walks, nature honors, etc.). Pathfinders will 
        experience a sense of wonder and worship as they observe and explore the beauty, the majesty, and the creative 
        power in nature. Fellowship with God will become more meaningful.</li>

        </div>
    <div className='col-md-6'>

<li>Teach Pathfinders specific skills and hobbies that will make their lives more meaningful and will occupy their 
    time with profitable accomplishments. Young people experience satisfaction and delight as they use their hands to 
    fashion 5 useful articles from wood, plastic, steel, clay, felt and yarn and as they discover how things work and 
    operate.</li>

    
      
<li>Encourage the Pathfinder to keep physically fit. This is one important way to safeguard against 
        idleness and boredom. Teach children to care for their body and establish habits that will provide for their 
        future happiness and usefulness (cf. 2T 536, 537; Educ. 195).</li>
<li>Give opportunity for the development of leadership by encouraging club members to work together and share in 
    leadership responsibility. This will teach them to learn the lessons of obedience, discipline, resourcefulness, 
    patriotism and the processes of group dynamics.</li>
<li>Seek to foster the harmonious development of the physical, social, intellectual, and spiritual life of the 
    Pathfinder. The invigoration of mind and body, the fostering of an unselfish spirit, the attention to recreational 
    and cultural activities, will provide stimulus for personal growth and act as an outlet for that restless energy, 
    which is so often a destructive source of danger to the young person.</li>
    </div>

    </div>
</ol>

</div>

</div>
</div>
</div>
</div>

<div id='Chart-Organization' className='row d-flex'>
      <div className='col-md-12 mb-4 d-flex'>
        <div className="card flex-fill">
          <div className="card-body text-white">
            <h5 className="card-title text-uppercase py-3" style={{ color: 'gold' }}>Pathfinder Chart Organization</h5>
            <p className="card-text white-text">The Pathfinder Club is a worldwide program organized and directed by the Youth 
              Department of the General Conference of the Seventh-day Adventist Church. It offers a wide range of 
              learning experiences for young people 10 to 15 years of age and is operated by the local Seventh-day 
              Adventist church under the direction and control of the local conference youth director. Each Pathfinder 
              Club is administered by a club director, deputy directors, counselors, instructors, chaplain, secretary, 
              and treasurer. The club is divided into separate units, each unit averaging between six to eight 
              Pathfinders, including a captain and scribe.</p>
          </div>
        </div>
      </div>

      <div className='col-md-9 mb-3 d-flex'>
          <div className="card flex-fill">            
              <div className="h5 card-header mt-3" style={{ color: 'gold' }}><FontAwesomeIcon icon={faTasks} className="text-burgundy mr-2" /> Steps to Organize a Pathfinder Club</div>
              <div className="card-body text-white">
              <ul className="list-unstyled">
                <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Counsel with the Conference Youth Ministries personnel.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Meet with the Pastor and Conference Youth Ministries personnel.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Present your plans to the Church Board. Church Board authorizes the organization of the 
                Pathfinder Club. Familiarize Board members with the Pathfinder philosophy, aims, and objectives.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Second meeting of the Church Board (or Nominating Committee) with Conference Youth Ministries 
                 personnel or Pathfinder area coordinator present, to elect Pathfinder director and deputies.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Inform congregation during Divine Service about Pathfinder Club, its objectives and program.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Pathfinder officers elect remaining Executive Committee members.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> First meeting of Pathfinder Executive Committee to elect remaining Pathfinder staff as needed.</li>
              </ul>
            </div>
          </div>
      </div>

      <div className='col-md-3 mb-3 d-flex'>
        <div className="card flex-fill">          
            <h5 className="card-header mt-3" style={{ color: 'gold' }}><FontAwesomeIcon icon={faUsers} className="text-burgundy mr-2" /> Pathfinder Club Membership</h5>
            <div className="card-body text-white">
            <ul className="list-unstyled">
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Pathfinder Club Members</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Counselors & Instructors</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Deputy Directors & other support staff</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Club Director</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Local Church Board</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Area Coordinators</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Local Conference Pathfinder Director</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Union Pathfinder Director</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Division Pathfinder Director</li>
              <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> General Conference Pathfinder Director</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='col-md-6 mb-4'>
        <div className="card">
          <div className="card-body text-white">
            <h5 className="card-title" style={{ color: 'gold' }}><FontAwesomeIcon icon={faFlag} className="text-burgundy mr-2" /> Pathfinder Club Details</h5>
            <p className="card-text white-text">The Pathfinder Club has a flag, bearing the Pathfinder’s Club Emblem. The flag is 
              flown at local and conference Pathfinder programs and activities. There is a specially designed full-dress 
              uniform and also a field uniform. The Pathfinder Club has a Pledge and Law, highlighting the spiritual 
              basis on which the club is built. The club operates on a 9, 10, 11, or12 month calendar year, with a 
              weekly or biweekly program of at least 1½, 2, or 3 hours’ duration. Within this 1½, 2, or 3 hour period 
              the Pathfinders perform drill and marching, crafts and/or hobbies and games, as well as taking part in 
              devotional activities and class work. There are six classes, with given names, which begin at 10 years of 
              age and conclude at 15 years. Each class is represented by a chosen color as listed.</p>
          </div>
        </div>
      </div>

      <div className='col-md-3 mb-4'>
        <div className="card">
          <div className="card-body text-white">
            <h5 className="card-title" style={{ color: 'gold' }}><FontAwesomeIcon icon={faGraduationCap} className="text-burgundy mr-2" /> Pathfinder Class Details</h5>
            <ul className="list-unstyled">
              <li><strong>Friend</strong> – 10 years – Blue</li>
              <li><strong>Companion</strong> – 11 years – Red</li>
              <li><strong>Explorer</strong> – 12 years – Green</li>
              <li><strong>Ranger</strong> – 13 years – Silver</li>
              <li><strong>Voyager</strong> – 14 years – Burgundy</li>
              <li><strong>Guide</strong> – 15 years – Gold</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='col-md-3 mb-4'>
        <div className="card">
          <div className="card-body text-white">
            <h5 className="card-title" style={{ color: 'gold' }}><FontAwesomeIcon icon={faMedal} className="text-burgundy mr-2" /> Pathfinder Awards</h5>
            <p className="card-text white-text">At the completion of the classwork requirements, the Pathfinder receives insignia as an award consisting of a pin, Pathfinder Class pocket strip and chevron at a special Investiture service.</p>
          </div>
        </div>
      </div>
  
</div>


  <div id='Membership-Requirements' className='row py-5'>
  <div className='col-md-12'>
    <div className='row mt-5'>

      <div className='col-md-12 mb-3'>
        <div className="card border-0">
          <div className="card-body text-white">
            <h5 className="card-title text-uppercase py-3 text-center" style={{ color: 'gold' }}>Pathfinder Club Membership Requirements</h5>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className="card border-0">
          <div className="card-body text-white">
            <ol>
              <div className='row'>
                <div className='col-md-6'>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> To be considered for Pathfinder Club membership the applicant must be at least 10 years of age and no more than 15 years of age. Where two clubs exist; children aged 10 to 12 will join the junior club and those aged 13 to 15 will join the teen club. (In some areas school level is used as the guidelines).</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Application forms for membership available from the club director/secretary must be completed and returned to the club staff committee for consideration and induction as a club member.</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> A Pathfinder Passport is available upon application to the club director/secretary. (Optional)</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> All members must pay membership and insurance fees as required by the club executive committee.</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Each Pathfinder should have and regularly wear the complete Pathfinder dress uniform and a club field uniform where applicable.</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Pathfinders must come to meetings and club-sponsored events in the uniform as indicated by the club director.</li>
                </div>
                <div className='col-md-6'>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Members must be faithful in attendance. Many clubs establish limits on absences and tardies, and Pathfinders who do not comply with these regulations may be placed on probation or be asked to withdraw from club membership.</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Pathfinders must learn and keep the Pledge and Law.</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> Club activities include crafts, outings, regular club meetings and Classwork, fund raising, camp-outs, outreach activities, honors, and other activities. The Pathfinder must agree before joining the club to participate and cooperate in these activities.</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> The Pathfinder’s parents must be willing to cooperate with the regulations and activities of the club. At times they will be asked to supply money and time to support their child’s membership.</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" /> All elected directors and regular staff are members of the club by virtue of their office.</li>
                </div>
              </div>
            </ol>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div id='Aim-Motto-Pledge-Law' className='row py-5'>
  <div className='col-md-3 mb-3'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-2 text-uppercase" style={{ color: 'gold' }}>
          <FontAwesomeIcon icon={faBullseye} className="mr-2 text-burgundy" />
          AIM
        </h2>
        <p className="card-text text-white">
          The Advent Message to all the world in my generation.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-3 mb-3'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-2 text-uppercase" style={{ color: 'gold' }}>
          <FontAwesomeIcon icon={faHeart} className="mr-2 text-burgundy" />
          Motto
        </h2>
        <p className="card-text text-white">
          The love of Christ compels us.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-3 mb-3'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-2 text-uppercase" style={{ color: 'gold' }}>
          <FontAwesomeIcon icon={faHandshake} className="mr-2 text-burgundy" />
          Pledge
        </h2>
        <p className="card-text text-white">
          By the grace of God,<br />
          I will be pure and kind and true.<br />
          I will keep the Pathfinder Law.<br />
          I will be a servant of God<br />
          and a friend to man.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-3 mb-3'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-2 text-uppercase" style={{ color: 'gold' }}>
          <FontAwesomeIcon icon={faBalanceScale} className="mr-2 text-burgundy" />
          Law
        </h2>
        <p className="card-text text-white">The Pathfinder Law is for me to:</p>
        <ol className="card-text text-white">
          <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" />Keep the morning watch.</li>
          <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" />Do my honest part.</li>
          <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" />Care for my body.</li>
          <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" />Keep a level eye.</li>
          <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" />Be courteous and obedient.</li>
          <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" />Walk softly in the sanctuary.</li>
          <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" />Keep a song in my heart.</li>
          <li><FontAwesomeIcon icon={faCheckCircle} className="text-burgundy mr-2" />Go on God's errands.</li>
        </ol>
      </div>
    </div>
  </div>
</div>


<div id='Meaning-Of-The-Pathfinder-Club-Emblem' className='row py-5'>
  <div className='col-md-12'>
    <h2 className="h2 mt-1 text-uppercase text-center bg-dark orange-text"> Meaning of the Pathfinder Club Emblem </h2>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-1 text-uppercase text-center" style={{ color: 'gold' }}>
          <FontAwesomeIcon icon={faCircle} className="mr-2 text-burgundy" />
          Red (Sacrifice)
        </h2>
        <ul className="card-text text-white">
          <li>Reminds us of Christ. "For God so loved the world..."</li>
          <li>"Present your bodies a living sacrifice..."</li>
        </ul>
      </div>
    </div>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-1 text-uppercase text-center" style={{ color: 'gold' }}>
          <FontAwesomeIcon icon={faBalanceScale} className="mr-2 text-burgundy" />
          Three sides
        </h2>
        <ul className="card-text text-white">
          <li>Completeness of the Trinity - Father, Son, Holy Spirit.</li>
          <li>Tripod of education: Mental, Physical, Spiritual.</li>
        </ul>
      </div>
    </div>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-5 text-uppercase text-center" style={{ color: 'gold' }}>
          <FontAwesomeIcon icon={faGem} className="mr-2 text-burgundy" />
          Gold (Excellence)
        </h2>
        <ul className="card-text text-white">
          <li>"I counsel thee to buy of me gold tried in the fire..."</li>
          <li>Standard of measurement for character building.</li>
        </ul>
      </div>
    </div>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-5 text-uppercase text-center" style={{ color: 'gold' }}>
          <FontAwesomeIcon icon={faShieldAlt} className="mr-2 text-burgundy" />
          Shield (Protection)
        </h2>
        <ul className="card-text text-white">
          <li>God is our shield. "Fear not... I am thy shield."</li>
          <li>"Above all, taking the shield of faith..."</li>
        </ul>
      </div>
    </div>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-5 text-uppercase text-center" style={{ color: 'white' }}>
          <FontAwesomeIcon icon={faChessKnight} className="mr-2 text-white" />
          White (Purity)
        </h2>
        <ul className="card-text text-white">
          <li>"He that overcometh... shall be clothed in white raiment."</li>
          <li>Aspiring for purity in life.</li>
        </ul>
      </div>
    </div>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-5 text-uppercase text-center blue-text">
          Blue (Loyalty)
        </h2>
        <ul className="card-text text-white">
          <li>Loyalty to God, parents, and church.</li>
          <li>Reflecting the character of our True Master Guide.</li>
        </ul>
      </div>
    </div>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-5 text-uppercase text-center gold-color">
          Sword (Bible)
        </h2>
        <ul className="card-text text-white">
          <li>The Word of God as our weapon.</li>
          <li>"The sword of the Spirit is the Word of God."</li>
        </ul>
      </div>
    </div>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-5 text-uppercase text-center gold-color">
          Inverted Triangle
        </h2>
        <ul className="card-text text-white">
          <li>Sacrificial service and humility.</li>
          <li>Following Jesus' teaching on servant leadership.</li>
        </ul>
      </div>
    </div>
  </div>

  <div className='col-md-4 mb-4'>
    <div className="card border-0 h-100">
      <div className="card-body">
        <h2 className="card-title h4 mt-5 text-uppercase text-center gold-color">
          Pathfinder Club
        </h2>
        <ul className="card-text text-white">
          <li>Part of the Seventh-day Adventist Church youth ministry.</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div id='Uniform' className='row py-5'>
  <div className='col-md-12  bg-dark mb-3'>
    <center><span className="h2 mt-5 text-uppercase py-5 white-text" >Uniform</span></center>
  </div>

  <div className='row'>
    <div className='col-md-12d-flex  mb-3'>
      <div className='card mb-4'>
        <div className='card-body text-white'>
      The Pathfinder uniform helps make the Pathfinder program real and visible. It is emblematic and representative of the
      worldwide club’s ideals and standards. Each individual member becomes a very vital representative of the organization,
      and wearing the uniform will help to provide a consciousness of belonging to a club that rightly represents the Adventist
      youth of today. If the uniform is worn as ordinary clothing it will have failed in its purpose.
      The uniform should always be neat and clean. To wear it commonly for ordinary play or work lowers its dignity. The
      Pathfinder Club program should be so valuable to each member that the uniform will be acquired and worn with enthusiasm.
    </div>
    </div>
    </div>

    <div className='col-md-4 d-flex mb-3'>
      <div className='card mb-4'>
        <div className='card-body text-white'>
          <span className="h6 mt-3 text-uppercase gold-color">Uniforms should be worn</span>
          <ul>
            <li>At all Pathfinder meetings</li>
            <li>At any public gathering when any or all act as: Messengers, Ushers, Guard of honor, Color Guards</li>
            <li>On occasions as specified by Pathfinder director</li>
            <li>At special Pathfinder services</li>
            <li>While engaging in witness activity, or community service such as Ingathering, distributing food baskets, flowers,
              literature, etc.</li>
          </ul>
        </div>
      </div>
    </div>

    <div className='col-md-4 d-flex mb-3'>
      <div className='card mb-4'>
        <div className='card-body text-white'>
          <span className="h6 mt-3 text-uppercase gold-color">Uniforms should not be worn</span>
          <ul>
            <li>By nonmembers</li>
            <li>When engaged in selling or solicitation for personal profit, or for commercial or political purposes</li>
            <li>At any time or place when it’s wearing discounts the organization or casts reflection upon the uniform, lowers
              its dignity and esteem, and makes it commonplace.</li>
          </ul>
        </div>
      </div>
    </div>

    <div className='col-md-4 d-flex mb-3'>
      <div className='card mb-4'>
        <div className='card-body text-white'>
          <span className="h6 mt-3 text-uppercase gold-color">The Uniform</span>
          <p>
            The official uniform for the Adventurer, Pathfinders, Master-Guide, and Ambassador is stipulated by the General
            Conference of Seventh-day Adventist® Youth Ministries Department. Any deviation or changes, including, but not limited
            to, the uniform’s style and color, must first be authorized by the General Conference of Seventh-day Adventist® Youth
            Ministries Department.
          </p>
        </div>
      </div>
    </div>

    <div className='col-md-3 d-flex mb-3'>
      <div className='card mb-4'>
        <div className='card-body text-white'>
          <span className="h6 mt-3 text-uppercase gold-color">Basic Uniform</span>
          <p>
            Should include the Pathfinder world emblem and the Pathfinder scarf. Other insignia and styles of uniforms are determined
            by each Division in keeping with the norms and economic conditions of the fields under their care.
          </p>
        </div>
      </div>
    </div>

    <div className='col-md-4 d-flex mb-3'>
      <div className='card mb-4'>
        <div className='card-body text-white'>
          <span className="h6 mt-3 text-uppercase gold-color">The Insignia</span>
          <p>
            While the uniform of the Pathfinder Club varies in regions or even countries around the world, the insignia and where
            they are placed are well nigh universally the same. Designing and setting the position of the insignia and/or working
            any changes or additions is the responsibility of the World Pathfinder Director and the General Conference in
            consultation with the divisions. Clubs, conferences, unions and divisions may make no exceptions or variations without
            the definite permission from the World Pathfinder Headquarters.<br /><br />
          </p>
        </div>
      </div>
    </div>

    <div className='col-md-5 d-flex mb-3'>
      <div className='card mb-4'>
        <div className='card-body text-white'>
          <span className="h6 mt-3 text-uppercase gold-color">The insignia are divided into two categories:</span>
          <p>
            1. Identification Insignia are the group of emblems that signify the organization to
            which the person belongs.<br />2. Recognition/award Insignia are emblems indicating class achievement, position, or
            special achievements in conduct or service.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div id='Insignia' className='row mt-0 mb-3 py-2 bg-dark white-text'>
  <center>
    <span className="h5 text-uppercase py-5 text-center gold-color">The following are a description and position of official Pathfinder Club Identification Insignia</span>
  </center>
</div>

<div className='row'>
  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-shield-alt me-2 text-burgundy"></i>Pathfinder Triangle Emblem
        </span>
        <p>This is the symbol that represents the Pathfinder Club. The 2-inch (5-cm.) triangle is worn on the cap, beret, etc. 
          The Pathfinder Club emblem 3-inch triangle (7.5-cm.) in the local language is worn on the right-hand sleeve, shoulder high.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-id-badge me-2 text-burgundy"></i>Pathfinder Club Name
        </span>
        <p>These are worn on the right sleeve of the shirt/girl’s blouse, and dress uniform jacket ½-inch (1.2 cm.) below the shoulder seam. It is worn above the staff name strip and Pathfinder triangle on the right-hand sleeve.</p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-user-tag me-2 text-burgundy"></i>Staff-Office Sleeve Strips
        </span>
        <p>Area/district director/coordinator, club director, deputy director, instructor, counselor, and junior counselor may wear a strip designating their position; it is centered below the club name 2-inch (5 cm.) below the shoulder seam and ¼-inch (.08-cm.) above the Pathfinder triangle on the right-hand sleeve.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-globe me-2 text-burgundy"></i>Pathfinder World Emblem
        </span>
        <p>This oval symbol, containing the Pathfinder triangle, represents the worldwide organization of Pathfinder Clubs of the Seventh-day Adventist Church. This symbol is worn on the left-hand sleeve of shirt/girl’s blouse, and dress uniform jacket. It is positioned ½-inch (1.2-cm.) below the shoulder seam.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-tie me-2 text-burgundy"></i>Neckerchief, Pathfinder
        </span>
        <p>This is yellow in color with the World Pathfinder emblem as the logo on the back. An optional slide is available. This may be worn by all inducted Pathfinders and Non-Master Guide Staff.</p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-user-graduate me-2 text-burgundy"></i>Neckerchief, Master Guide
        </span>
        <p>This is yellow in color with the Master Guide Crest printed on the tip of the triangle. The distinctive Master Guide slide with the Master Guide Crest is used with the neckerchief. It is to be worn only by those invested as Master Guides.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-chevron-up me-2 text-burgundy"></i>Pathfinder Class Chevrons
        </span>
        <p>The following are a description of Pathfinder recognition insignia: Are located on the left sleeve below the Pathfinder world emblem. The order from top to bottom is Master Guide, Guide, Voyager, Ranger, Explorer, Companion, and Friend. All persons (Master Guide Included) should wear only the class insignia in which they have been invested.</p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-star me-2 text-burgundy"></i>Master Guide Star
        </span>
        <p>An embroidered gold star is worn on left-hand sleeve. When all Pathfinder classes and the Master Guide course have been completed, the Master Guide Star and all the individual chevrons may be worn.</p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-certificate me-2 text-burgundy"></i>Pathfinder Class Pins
        </span>
        <p>Upon Investiture for each class, the Pathfinder obtains a pin of recognition to be worn on the uniform: These may be worn centered across the top of the left pocket. The Master Guide pin would be placed in the center at the top of the left pocket with pins of other classes in which the Master Guide has been invested centered in a row below, or the Master Guide may choose to wear only the Master Guide pin. When advanced level insignia have been earned, the class pins may be placed on the sash.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-pocket me-2 text-burgundy"></i>Class Pocket Strip
        </span>
        <p>Centered just above the left pocket of the shirt or blouse is the Pathfinder Class pocket strip for the highest Pathfinder Class in which the individual has been invested. It should be level with the top of the uniform shirt pocket.</p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-ribbon me-2 text-burgundy"></i>Advanced Pathfinder Class Ribbon/Bar
        </span>
        <p>This is worn only by those completing the requirements, on the left side of shirt or dress just above the Pathfinder Class pocket strip.</p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-medal me-2 text-burgundy"></i>Pathfinder Excellence Ribbon
        </span>
        <p>This is worn on the left side of shirt, blouse, or dress above the pocket. It is usually above the Pathfinder Class pocket strip or Advanced Pathfinder Class Ribbons.</p>
      </div>
    </div>
  </div>

  <div className='col-md-4 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-user-captain me-2 text-burgundy"></i>Captain and Scribe Badges
        </span>
        <p>To be worn by captain and scribe on the sash, or positioned above the right pocket, on the shirt/blouse.</p>
      </div>
    </div>
  </div>

  <div className='col-md-8 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body'>
        <span className="h6 mt-3 text-uppercase gold-color">
          <i className="fas fa-medal me-2 text-burgundy"></i>Pathfinder Honor Sash
        </span>
        <p>This is worn over the right shoulder (under neckerchief) and under the left arm, the lower point of the sash resting against the left side of the body. Sashes may be wide (6 inches [15 cm]) or narrow (3 inches [7.5 cm]). Only earned honors (cloth or small metal) may be displayed. If advanced ribbons or bars are awarded, they are worn above the Pathfinder Class level pocket strip or above the left pocket of the shirt/blouse.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-6 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body text-white'>
        <span className="h6 mt-3 text-uppercase gold-color">Pathfinder Staff Service Stars</span>
        <p>
          These are obtained from the conference/mission director. There are two options: 1) Embroidered fabric stars may be worn on the right sleeve centered ¼-inch (0.08-cm.)
          below the point of the 3-inch (7.5-cm.) Pathfinder triangle emblem. 2) Metal stars may be worn on the left pocket.
          Only one star with the numeral indicating the accumulated years of verifiable service should be worn.Service stars are awarded annually by the conference/mission that keeps a record of the years of service.
          Epaulettes, Stripes, Stars of Rank.<br /><br />An optional green epaulette is worn by teen Pathfinders, junior counselors, counselors and instructors.
          Optional gold stripes, bars, stars or various colored braids are worn for each level of conference leadership.
          These items are selected by local conferences, unions, or Divisions. Not more than one option may be worn and must be standard within the organization level selecting in.
        </p>
      </div>
    </div>
  </div>

  <div className='col-md-6 d-flex mb-3'>
    <div className='card mb-4'>
      <div className='card-body text-white'>
        <span className="h6 mt-3 text-uppercase gold-color">Militarism</span>
        <p>The use of a uniform in Pathfindering provides many positive results. It meets certain psychological needs of the Pathfinder age bracket; it encourages what could be called upgraded behavior; and it provides a sense of cohesiveness.
          <br /><br />In most regions the official uniform adopted in many ways resembles local military uniforms as local laws will or will not allow. While this is acceptable to a degree,
          those who have the decision-making authority must be very careful to not create nor allow militarism to creep into Pathfindering.
          Militarism is defined as the use of military discipline and the wholesale adoption of military-style uniforms that would create confusion in the minds of those who do not know what Pathfindering represents.
          Around the world there are governments that are very sensitive to the existence of paramilitary organizations. Pathfindering in other parts of the world cannot be seen by these governments as involved in training guerrilla or terrorist-type personnel.
          Camouflage, combat boots, “blousing” of pant legs, and other similar military practices in uniforms should not be used at all.
          No military insignia are allowed. The Pathfinder scarf should always be worn as part of the dress (Class A) uniform to distance it from the traditional military uniform.
          Attaching weapons to dress uniforms such as swords, bayonets, or machetes and guns even if they are fake ones should not be allowed even in drill ceremonies.</p>
      </div>
    </div>
  </div>
</div>



<div className='row'>
      <div className='col-md-12 '>
        <h2 className="h4 mb-4 text-uppercase text-center py-5 bg-dark gold-color">Class A &#8211; Class B: PATHFINDER UNIFORM</h2>
      </div>

      <div className='col-md-6 mb-2 d-flex'>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title gold-color">
              <i className="fas fa-star text-burgundy"></i> Class A Uniform
            </h5>
            <h6 className="h6 text-uppercase">The &#8220;Class A&#8221; uniform is the Pathfinder dress uniform.</h6>
            <table className="table white-text table-sm">
              <tbody>
                <tr><td>Shirt/Blouse</td><td>White</td></tr>
                <tr><td>Pants/Skirt</td><td>Green</td></tr>
                <tr><td>Hat</td><td>Free between Hats/Caps/Baret – The logo cannot be changed in any way</td></tr>
                <tr><td>Scarf</td><td>Yellow</td></tr>
                <tr><td>Socks</td><td>White</td></tr>
                <tr><td>Shoes</td><td>Black</td></tr>
                <tr><td>Sash</td><td>Green</td></tr>
                <tr><td>Neck Tie</td><td>Black or Green</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='col-md-6 mb-2 d-flex'>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title gold-color">
              <i className="fas fa-campground text-burgundy"></i> Class B Uniform
            </h5>
            <span className="h6 text-uppercase">The “Class B” uniform is the Pathfinder &#8220;field&#8221; uniform. The field uniform is used during most club meetings, and on camp-outs.</span>
            <div className="table-responsive mb-4">
              <table className="table white-text table-sm">
                <tbody>
                  <tr><td>Shirt/Blouse</td><td>Khaki</td></tr>
                  <tr><td>Pants/Skirt</td><td>Green or Khaki</td></tr>
                  <tr><td>Hat</td><td>Free between Hats/Caps/Baret – The logo cannot be changed in any way</td></tr>
                  <tr><td>Scarf</td><td>Yellow</td></tr>
                  <tr><td>Socks</td><td>Black / Dark Colors</td></tr>
                  <tr><td>Shoes</td><td>Black</td></tr>
                  <tr><td>Sash</td><td>Green</td></tr>
                  <tr><td>Neck Tie</td><td>Black or Green</td></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className='row mt-5'>
      <div className='col-md-12 bg-dark mb-3'>
        <center><span className="h2 mb-4 mt-5 text-uppercase py-5 gold-color">Pathfinders Shirt</span></center>
      </div>
      <div className='col-md-6 mb-2 d-flex'>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title gold-color">
              <i className="fas fa-tshirt text-burgundy"></i> Front Of Shirt Without Sash
            </h5>
            <img className="img-fluid mb-5" src={Pathfinder_Insignia_Positions_on_Shirt_no_tie} alt="Front of Shirt Without Sash" />
          </div>
        </div>
      </div>
      <div className='col-md-6 mb-2 d-flex'>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title gold-color">
              <i className="fas fa-tshirt text-burgundy"></i> Front Of Shirt With Sash
            </h5>
            <img className="img-fluid mb-5" src={Pathfinder_Insignia_Positions_on_Shirt_and_the_Sash_no_tie} alt="Front of Shirt With Sash" />
          </div>
        </div>
      </div>
    </div>



    <div className='row'>
        <div className='col-md-12 bg-dark mt-5 mb-2'>
          <center><span className="h2 mb-4 mt-5 text-uppercase py-5 gold-color">Pathfinders Shirt Sleeves</span></center>
        </div>
        </div>

        <div className='row'>
        <div className='col-md-6 mb-2 d-flex'>
          <div className="card flex-fill">
          <h5 className="card-title gold-color">
                <i className="fas fa-sleeve text-burgundy"></i> Left Sleeve
              </h5>
            <div className="card-body">
              <img className="img-fluid" src={Pathfinder_Uniform_Left_Sleeve} alt="Left Sleeve" />
            </div>
          </div>
        </div>
        <div className='col-md-6 mb-2 d-flex'>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title gold-color">
                <i className="fas fa-sleeve text-burgundy"></i> Right Sleeve
              </h5>
              <img className="img-fluid mb-5" src={Pathfinder_Uniform_Right_Sleeve} alt="Right Sleeve" />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12 mb-4 mt-5 bg-dark'>
          <center><span className="h2 text-uppercase py-5 gold-color">Epaulettes For Coordinators</span></center>
        </div>
        <div className='col-md-2 d-flex'>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title gold-color">
                <i className="fas fa-shield-alt text-burgundy"></i> Area
              </h5>
              <img className="img-fluid mb-5" src={Area_Pathfinder_Coordinator_Epaulette} alt="Area Epaulette" />
            </div>
          </div>
        </div>
        <div className='col-md-3 d-flex'>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title gold-color">
                <i className="fas fa-shield-alt text-burgundy"></i> Conference
              </h5>
              <img className="img-fluid mb-5" src={Conference_Pathfinder_Director_Epaulette} alt="Conference Epaulette" />
            </div>
          </div>
        </div>
        <div className='col-md-2 d-flex'>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title gold-color">
                <i className="fas fa-shield-alt text-burgundy"></i> Union
              </h5>
              <img className="img-fluid mb-5" src={Union_Pathfinder_Director_Epaulette} alt="Union Epaulette" />
            </div>
          </div>
        </div>
        <div className='col-md-2 d-flex'>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title gold-color">
                <i className="fas fa-shield-alt text-burgundy"></i> Division
              </h5>
              <img className="img-fluid mb-5" src={Division_Pathfinder_Director_Epaulette} alt="Division Epaulette" />
            </div>
          </div>
        </div>
        <div className='col-md-3 d-flex'>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title gold-color">
                <i className="fas fa-shield-alt text-burgundy"></i> General Conference
              </h5>
              <img className="img-fluid mb-5" src={General_Conference_Pathfinder_Director_Epaulette} alt="General Conference Epaulette" />
            </div>
          </div>
        </div>
      </div>

      <div id='Song' className='row py-5'>
        <div className='col-md-12 text-center'>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title gold-color">
                <i className="fas fa-music text-burgundy"></i> Pathfinders Song
              </h5>
              <img className="img-fluid mt-5 mb-5" src={pathfinder_song} alt="Pathfinders Song" />
            </div>
          </div>
        </div>
      </div>



          </div>
        </div>
      </article>
    </section>

</div>


 

</main>


<Footer />
      
    </div>

     {/* Dark Overlay */}
     <div className="overlay-background" style={{ backgroundImage: `url(${planningCommitImage})` }}></div>
  </>


  );
}

export default Pathfinders;
