import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/AuthProvider'

//window.SERVER_APP_API = 'http://localhost:3001'
//window.SERVER_APP_API_PHP = 'http://localhost/react/wcay/src/server' //server files
window.SERVER_APP_API_PHP = 'https://www.wcay.kanchtech.com' //server files

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
